import React from 'react'
import { MathJax } from 'better-react-mathjax'
import MathjaxContext from './MathjaxContent'

interface PreviewProps {
  text: string
}

const ShowPreview: React.FC<PreviewProps> = ({ text }) => {
  return (
    <MathjaxContext>
      <div className={'show_preview'}>
        <MathJax inline={true}>{text}</MathJax>
      </div>
    </MathjaxContext>
  )
}

export default ShowPreview
