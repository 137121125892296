import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { Redirect } from 'react-router-dom'
import { useCheckSms, useSaveUserName, useSendSms } from '../../queries/mutation'
import { rootPaths } from '../../routing/rootPaths'
import { AuthService } from '../../services/AuthService'
import './Login.scss'

const Login: React.FC = () => {
  const [step, setStep] = useState<number>(1)
  const sendSms = useSendSms(setStep)
  const checkSms = useCheckSms(setStep)
  const saveUserName = useSaveUserName(setStep)

  // on finish
  const onFinish = (values: {
    phone: string
    code?: number
    full_name?: string
  }) => {
    if (step === 1) {
      const data = {
        phone: values.phone.replace(/\s/g, ''),
      }
      sendSms.mutate(data)
    } else if (step === 2) {
      const data = {
        phone: values.phone.replace(/\s/g, ''),
        code: Number(values.code)!,
      }
      checkSms.mutate(data)
    } else if (step === 3) {
      const data = {
        full_name: values.full_name!,
      }
      saveUserName.mutate(data)
    }
  }

  // button text
  const buttonText = () => {
    if (step === 1) {
      return 'Kirish'
    } else if (step === 2) {
      return 'Tasdiqlash'
    } else if (step === 3) {
      return 'Boshlash'
    }
    return 'Kirish'
  }

  // redirect
  const redirect = () => {
    if (AuthService.checkLogin() && step === 1) {
      return <Redirect to={rootPaths.myGroup} />
    } else return null
  }

  return (
    <div className="loginCont">
      {redirect()}
      <div className="loginCont_main">
        <div className="logo">
          <span>Logo</span>
        </div>
        <p className="name">teacher.aim.uz</p>
        <Form className="loginForm" onFinish={onFinish} layout="vertical">
          {step !== 3 && (
            <>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Telofon raqamingizni kiriting!',
                  },
                ]}
              >
                <ReactInputMask
                  mask="+\9\98 99 999 99 99"
                  disabled={step === 2 && true}
                >
                  {() => <Input placeholder="Telefon raqam" />}
                </ReactInputMask>
              </Form.Item>
              {step === 2 && (
                <Form.Item
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: 'Tasdiqlash kodini kiriting!',
                    },
                  ]}
                >
                  <Input
                    className="verification_code"
                    placeholder="Tasdiqlash kodi"
                    type={'number'}
                  />
                </Form.Item>
              )}
            </>
          )}
          {step === 3 && (
            <Form.Item
              name="full_name"
              rules={[
                {
                  required: true,
                  message: 'Ism va familiyangizni kiriting!',
                },
              ]}
            >
              <Input placeholder="Ism-familiyangiz" />
            </Form.Item>
          )}
          <Form.Item>
            <Button
              loading={
                sendSms.isLoading || checkSms.isLoading || saveUserName.isLoading
              }
              htmlType="submit"
              className="blue_ant_button"
              type="primary"
            >
              {buttonText()}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Login
