import React, { useState } from 'react'
import {
  useAimTests,
  useSubjectsHasTest,
  useThemesInsideSubject,
} from '../../../queries/queries'
import { ISelectedTestData, TestData } from '../../../models/test-page/TestData'
import Filtr from '../../../assets/icons/Filtr'
import { Button, Pagination, Popover, Select, Spin, Table } from 'antd'
import FileIcon from '../../../assets/icons/FileIcon'
import CancelIcon from '../../../assets/icons/CancelIcon'
import FileText from '../../../assets/icons/FileText'
import { Subject } from '../../../models/subject/Subject'
import ThemesFilterModal from './ThemesFilterModal'

const { Column } = Table

interface IProps {
  searchText: string
  setModalVisible: (visible: boolean) => void
  selectedTests: ISelectedTestData[]
  setSelectedTests: (tests: ISelectedTestData[]) => void
}

const AimTests: React.FC<IProps> = ({
  searchText,
  setModalVisible,
  selectedTests,
  setSelectedTests,
}) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  // const [selectedTests, setSelectedTests] = useState<TestData[]>([])
  const [openSubjectsFilter, setOpenSubjectsFilter] = useState(false)
  const [openThemesModal, setOpenThemesModal] = useState(false)
  const [filteredSubject, setFilteredSubject] = useState<Subject>()
  const [selectedThemes, setSelectedThemes] = useState<number[]>([])

  const { data, isLoading } = useAimTests(
    page,
    pageSize,
    selectedThemes,
    filteredSubject?.id,
    searchText
  )
  const { data: themes, isLoading: themesLoading } = useThemesInsideSubject(
    filteredSubject?.id || 0
  )

  const { data: subjects, isLoading: isLoadingSubjects } = useSubjectsHasTest()

  const onRowSelect = (record: TestData, selected: boolean) => {
    let tests = selectedTests.slice()
    if (selected) {
      tests.push(record)
    } else {
      tests = tests.filter((test) => test.test_id !== record.test_id)
    }
    setSelectedTests(tests)
  }

  const returnSelectedRowKeys: () => number[] = () => {
    const ids = []
    for (let i = 0; i < selectedTests.length; i++) {
      ids.push(selectedTests[i].test_id)
    }
    return ids
  }

  const onSelectAll = (
    selected: boolean,
    selectedRows: TestData[],
    changeRows: TestData[]
  ) => {
    let tests = selectedTests.slice()
    if (selected) {
      for (let i = 0; i < changeRows.length; i++) {
        if (!tests.includes(changeRows[i])) {
          tests.push(changeRows[i])
        }
      }
    } else {
      for (let i = 0; i < changeRows.length; i++) {
        tests = tests.filter((test) => test.test_id !== changeRows[i].test_id)
      }
    }
    setSelectedTests(tests)
  }

  const rowSelection = {
    onSelect: onRowSelect,
    selectedRowKeys: returnSelectedRowKeys(),
    onSelectAll: onSelectAll,
  }

  // clear selected tests
  const clearSelectedTests = () => {
    setSelectedTests([])
  }

  // is empty selected tests
  const isEmptySelectedTests = () => {
    if (selectedTests.length > 0) {
      return false
    } else if (selectedTests.length <= 0) {
      return true
    }
  }

  const handleChangeSubjectsFilterPopover = (visible: boolean) => {
    setOpenSubjectsFilter(visible)
  }

  const selectSubject = (subject: Subject) => {
    setPage(1)
    setFilteredSubject(subject)
    setOpenSubjectsFilter(false)
  }

  const clearSelectedSubject = () => {
    setPage(1)
    setSelectedThemes([])
    setFilteredSubject(undefined)
  }

  const openThemesModalFunc = () => {
    setOpenThemesModal(true)
  }

  const closeThemesModal = (isClear: boolean) => {
    if (isClear) setSelectedThemes([])
    setOpenThemesModal(false)
  }

  const handleConfirmThemes = (ids: number[]) => {
    setPage(1)
    setSelectedThemes(ids)
  }

  // open modal
  const openModal = () => {
    setModalVisible(true)
  }

  // start test
  const startTest = (record: TestData) => {
    const testData = {
      test_id: record.test_id,
      test_name: record.test_name,
      subject_name: record.subject_name,
      question_count: record.question_count,
    }
    setSelectedTests([testData])
    openModal()
  }

  const subjectsPopoverContent = (
    <div className={'subjects_popover'}>
      <h3>Fanlarni saralash</h3>
      <div className={'subjectsList'}>
        {subjects?.map((subject, index) => (
          <div
            onClick={() => selectSubject(subject)}
            key={index}
            className={'subject'}
          >
            {subject.name}
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <div className="myTests">
      <Spin spinning={isLoadingSubjects}>
        <div className="filtr">
          {filteredSubject?.name ? (
            <>
              <div className={'selectedSubject'}>
                <span className={'subject_name'}>{filteredSubject.name}</span>
                <span
                  title={"Saralangan fanni o'chirish"}
                  onClick={clearSelectedSubject}
                  className={'icon'}
                >
                  X
                </span>
              </div>
              <Spin spinning={themesLoading}>
                <div onClick={openThemesModalFunc} className={'filter_themes'}>
                  <Filtr />
                </div>
              </Spin>
            </>
          ) : (
            <Popover
              content={subjectsPopoverContent}
              trigger="click"
              visible={openSubjectsFilter}
              onVisibleChange={handleChangeSubjectsFilterPopover}
            >
              <div className={'d-flex align-center'} style={{ cursor: 'pointer' }}>
                <Filtr />
                <p>Filtr</p>
              </div>
            </Popover>
          )}
        </div>
      </Spin>
      {!isEmptySelectedTests() && (
        <div className="extraRow">
          <p className="count">Tanlangan guruhlar: {selectedTests.length}</p>
          <div className="moreActions">
            <Button type="primary" className="white_btn" onClick={openModal}>
              <FileIcon />
              <span>Test o'tkazish</span>
            </Button>
            <div className="cancelBtn" onClick={clearSelectedTests}>
              <CancelIcon />
            </div>
          </div>
        </div>
      )}
      <Table
        loading={isLoading}
        dataSource={data?.tests}
        pagination={false}
        className="tableMyTest"
        rowKey={(record: TestData) => record.test_id}
        rowSelection={rowSelection}
      >
        <Column align="left" title="Test nomi" dataIndex={'test_name'} />
        <Column align="left" title="Fan" dataIndex={'subject_name'} />
        <Column align="center" title="Sana" dataIndex={'date'} />
        <Column align="right" title="Savollar soni" dataIndex={'question_count'} />
        {isEmptySelectedTests() && (
          <Column
            align="center"
            render={(record) => (
              <Button
                className="green_btn"
                type="primary"
                onClick={() => startTest(record)}
              >
                <FileText />
                <span>Boshlash</span>
              </Button>
            )}
          />
        )}
      </Table>
      <div className="pagination" style={{ width: '100%', padding: '0.5rem 1rem' }}>
        <div className="pageSize">
          <p>Har bir sahifadagi qatorlar soni :</p>
          <Select defaultValue={10} onSelect={(pageSize) => setPageSize(pageSize)}>
            <Select.Option value="10">10</Select.Option>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
          </Select>
        </div>
        <Pagination
          total={Number(data?.count) || 10}
          current={page}
          pageSize={pageSize}
          showSizeChanger={false}
          onChange={(page) => setPage(page)}
        />
      </div>
      <ThemesFilterModal
        data={themes || []}
        handleConfirmThemes={handleConfirmThemes}
        open={openThemesModal}
        close={closeThemesModal}
        selectedThemes={selectedThemes}
      />
    </div>
  )
}

export default AimTests
