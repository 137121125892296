import { useQuery } from 'react-query'
import $api from '../hooks/useAxiosInstance'
import { AddressDistrictData } from '../models/address/AddressDistrictData'
import { AddressRegionData } from '../models/address/AddressRegionData'
import { GroupData, GroupDataResponse } from '../models/group-page/GroupData'
import {
  OneStudentGroups,
  OneStudentInfo,
  StudentDataResponse,
} from '../models/detailedGroup/StudentsData'
import { TestDataResponse } from '../models/test-page/TestData'
import {
  addressEndpoints,
  directionEndpoints,
  myPageEndpoints,
  studentsEndpoints,
  subjectTestEndpoints,
  subjectEndpoints,
  teacherGroupEndpoints,
  testEndpoints,
  yearsEndpoints,
  aimTestsEndpoints,
  aimSubjectsEndpoints,
  blockEndpoints,
  attachedTestEndpoints,
  isAuthEndpoints,
} from '../utils/constants/api'
import { queryNames } from './queryNames'
import { User } from '../models/user/User'
import { DirectionData } from '../models/direction/DirectionData'
import { SubjectTestData } from '../models/subject-test/SubjectTestData'
import { TestList } from '../models/test-page/TestList'
import { Subject } from '../models/subject/Subject'
import { Years } from '../models/years/Years'
import { BaseModel } from '../models/BaseModel'
import { BlockDataResponse } from '../models/block-page/Block'
import { AttachedTestDataResponse } from '../models/detailedGroup/AttachedTestsData'
import { TestingStudentsDataResponse } from '../models/detailedGroup/TestingStudentsData'
import { AuthService } from '../services/AuthService'

export function useGroup(
  page: number = 1,
  pageSize: number = 10,
  search: string = ''
) {
  let url = `${teacherGroupEndpoints.INDEX}?page=${page}&size=${pageSize}`
  if (search.length > 2) {
    url += `&search=${search}`
  }
  return useQuery<GroupDataResponse>(
    [queryNames.TEACHER_GROUP, page, pageSize, search],
    async () => {
      const response = await $api.get(url)
      return response.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

export function useOneGroup(id: number) {
  let url = `${teacherGroupEndpoints.INDEX}?id=${id}`
  return useQuery<GroupData>(
    [queryNames.ONE_GROUP, id],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

export function useStudents(
  id: number,
  page: number = 1,
  pageSize: number = 10,
  searchText: string
) {
  let url = `${studentsEndpoints.INDEX}?group_id=${id}&page=${page}&size=${pageSize}`
  if (searchText.length > 2) {
    url += `&search=${searchText}`
  }
  return useQuery<StudentDataResponse>(
    [queryNames.STUDENTS, page, pageSize, id, searchText],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

export function useAttachedTests(
  group_id: number,
  page: number = 1,
  pageSize: number = 10,
  searchText: string
) {
  let url = `${attachedTestEndpoints.INDEX}?group_id=${group_id}&page=${page}&size=${pageSize}`
  if (searchText.length > 2) {
    url += `&search=${searchText}`
  }
  return useQuery<AttachedTestDataResponse>(
    [queryNames.ATTACHED_TESTS, group_id, page, pageSize, searchText],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

// testing students
export function useTestingStudents(
  group_id: number,
  template_id: number,
  page: number = 1,
  size: number = 10,
  searchText: string
) {
  let url = `${attachedTestEndpoints.INDEX}?group_id=${group_id}&shablon_id=${template_id}&page=${page}&size=${size}`
  if (searchText.length > 2) {
    url += `&search=${searchText}`
  }
  return useQuery<TestingStudentsDataResponse>(
    [queryNames.TESTING_STUDENTS, group_id, template_id, page, size, searchText],
    async () => {
      const res = await $api.get(url)
      return res.data
    }
  )
}

export function useOneStudentInfo(id: number) {
  const url = `${studentsEndpoints.INDEX}?id=${id}`
  return useQuery<OneStudentInfo>(
    [queryNames.ONE_STUDENT_INFO, id],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  )
}

export function useOneStudentGroups(id: number) {
  const url = `${studentsEndpoints.CREATE}?id=${id}`
  return useQuery<OneStudentGroups[]>(
    [queryNames.ONE_STUDENT_GROUPS, id],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  )
}

// active groups
export function useActiveGroups(profile_id?: number) {
  let url = `${studentsEndpoints.INDEX}`
  if (profile_id) {
    url += `?profile_id=${profile_id}`
  }
  return useQuery<{ id: number; name: string }[]>(
    [queryNames.ACTIVE_GROUPS, profile_id],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

//my test get api
export function useMyTests(
  page: number = 1,
  pageSize: number = 5,
  searchText: string
) {
  let url = testEndpoints.INDEX + '?page=' + page + '&size=' + pageSize
  if (searchText.length > 2) {
    url += `&search=${searchText}`
  }
  return useQuery<TestDataResponse>(
    [queryNames.MY_TEST, page, pageSize, searchText],
    async () => {
      const response = await $api.get(url)
      return response.data
    }
  )
}

//blok get api
export function useMyBlock(
  page: number = 1,
  pageSize: number = 5,
  searchText: string
) {
  let url = blockEndpoints.INDEX + '?page=' + page + '&size=' + pageSize
  if (searchText.length > 2) {
    url += `&search=${searchText}`
  }
  return useQuery<BlockDataResponse>(
    [queryNames.BLOCK, page, pageSize, searchText],
    async () => {
      const response = await $api.get(url)
      return response.data
    }
  )
}

//profil page get api
export function useMyPage() {
  const url = myPageEndpoints.INDEX
  return useQuery<User>(
    [queryNames.MY_PAGE],
    async () => {
      const response = await $api.get(url)
      return response.data
    },
    {
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
    }
  )
}

//address REGION get api
export function useAddress() {
  const url = addressEndpoints.INDEX
  return useQuery<AddressRegionData>([queryNames.ADDRESSREGION], async () => {
    const response = await $api.get(url)
    return response.data
  })
}

// addrss district get api
export function useAddressDistrict(id: number) {
  const url = addressEndpoints.INDEX + '?id=' + id
  return useQuery<AddressDistrictData>(
    [queryNames.ADDRESSDISTRICT, id],
    async () => {
      const response = await $api.get(url)
      return response.data
    },
    {
      enabled: !!id,
    }
  )
}

//direction get api
export function useDirection() {
  const url = directionEndpoints.INDEX
  return useQuery<DirectionData>([queryNames.DIRECTION], async () => {
    const res = await $api.get(url)
    return res.data
  })
}

//subject-test get api
export function useSubjectTest() {
  const url = subjectTestEndpoints.INDEX
  return useQuery<SubjectTestData>(
    [queryNames.SUBJECT_TEST],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchInterval: Infinity,
    }
  )
}
// subjects
export function useSubjcet() {
  return useQuery<Subject[]>(
    [queryNames.SUBJECT],
    async () => {
      const { data } = await $api.get(subjectEndpoints.INDEX)
      return data
    },
    {
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
    }
  )
}

// tests inside subjects
export function useOneTest(id: string) {
  return useQuery<TestList>(
    [queryNames.ONE_TEST, id],
    async () => {
      const { data } = await $api.get(`${testEndpoints.INDEX}?test_id=${id}`)
      return data
    },
    {
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      enabled: id !== 'new',
    }
  )
}

// years
export function useYears() {
  return useQuery<any, Years[]>(
    [queryNames.YEARS],
    async () => {
      const { data } = await $api.get(yearsEndpoints.INDEX)
      return data
    },
    {
      refetchInterval: Infinity,
    }
  )
}

//aim tests get api
export function useAimTests(
  page: number = 1,
  pageSize: number = 10,
  selectedThemes: number[],
  subjectId?: number,
  searchText: string = ''
) {
  let url = aimTestsEndpoints.INDEX + '?page=' + page + '&size=' + pageSize
  if (subjectId) url += `&subject_id=${subjectId}`
  if (selectedThemes.length > 0) {
    url += `&lesson_ids=${selectedThemes.toString()}`
  }
  if (searchText.length > 2) {
    url += `&search=${searchText}`
  }
  return useQuery<TestDataResponse>(
    [
      queryNames.AIM_TEST,
      page,
      pageSize,
      selectedThemes.toString(),
      subjectId,
      searchText,
    ],
    async () => {
      const response = await $api.get(url)
      return response.data
    },
    {
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
    }
  )
}

//aim tests get subjects has tests
export function useSubjectsHasTest() {
  return useQuery<Subject[]>(
    [queryNames.SUBJECTS_HAS_TEST],
    async () => {
      const response = await $api.get(aimSubjectsEndpoints.INDEX)
      return response.data.subject
    },
    {
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
    }
  )
}

//aim tests get subjects has tests
export function useThemesInsideSubject(subject_id: number) {
  return useQuery<BaseModel[]>(
    [queryNames.SUBJECTS_THEME],
    async () => {
      const response = await $api.get(
        aimSubjectsEndpoints.INDEX + `?subject_id=${subject_id}`
      )
      return response.data
    },
    {
      refetchInterval: Infinity,
      enabled: subject_id !== 0,
      refetchOnWindowFocus: false,
    }
  )
}

// is auth
export function useIsAuth() {
  return useQuery<{ message: boolean; token?: string }>(
    [queryNames.IS_AUTH],
    async () => {
      const res = await $api.get(isAuthEndpoints.INDEX)
      return res.data
    },
    {
      // retry: false,
      // refetchOnWindowFocus: false,
      // staleTime: Infinity,
      // cacheTime: Infinity,
      // refetchInterval: Infinity,
      onSuccess: (preview) => {
        if (preview.message) {
          AuthService.setToken(preview.token!)
        } else {
          AuthService.clearToken()
        }
      },
    }
  )
}
