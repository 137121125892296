import { message } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import $api from '../hooks/useAxiosInstance'
import {
  blockEndpoints,
  attachedTestEndpoints,
  createTestEndpoints,
  importEndpoints,
  myPageEndpoints,
  questionEndpoints,
  removeImageEndpoints,
  studentsEndpoints,
  teacherGroupEndpoints,
  testEndpoints,
  virtualTestEndpoints,
  dtmTestEndpoints,
  sendSmsEndpoints,
  checkSmsEndpoints,
  saveUserNameEndpoints,
  logoutEndpoints,
} from '../utils/constants/api'
import { queryNames } from './queryNames'
import { mainPaths, rootPaths } from '../routing/rootPaths'
import React from 'react'
import { andQuestions, updateQuestion } from '../store/reducers/questionListSlice'
import { ImportedQuestion, Question } from '../models/test-page/Question'
import { FormInstance } from 'rc-field-form'
import { DTMPoyloadData } from '../models/test-page/DTMData'
import { useAppDispatch } from '../hooks/redux'
import { selectedGroupsSlice } from '../store/reducers/SelectedGroupsSlice'
import { useHistory } from 'react-router-dom'
import { AuthService } from '../services/AuthService'

export function useCreateEditGroup(setVisible: (visible: boolean) => void) {
  const qc = useQueryClient()
  return useMutation(
    async (data: { name: string; description: string; id?: number }) => {
      const res = await $api.post(teacherGroupEndpoints.INDEX, data)
      return res.data.data
    },
    {
      onSuccess: (_, data) => {
        qc.invalidateQueries([queryNames.TEACHER_GROUP])
        setVisible(false)
        if (data.id) {
          message.success('Guruh yangilandi!')
        } else {
          message.success('Guruh yaratildi!')
        }
      },
    }
  )
}

export function useDeleteGroup() {
  const qc = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(teacherGroupEndpoints.INDEX + `?id=${id}`)
      return res.data.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.TEACHER_GROUP])
        message.success('Guruh o`chirildi!')
      },
    }
  )
}

export function useArchivateGroup() {
  const qc = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await $api.put(teacherGroupEndpoints.INDEX + `?id=${id}`)
      return res.data.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.TEACHER_GROUP])
        message.success('Guruh arxivlandi!')
      },
    }
  )
}

// delete my test
export function useDeleteTests() {
  const qc = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(testEndpoints.INDEX + `?test_id=${id}`)
      return res.data.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.MY_TEST])
        message.success("Testlar o'chirildi")
      },
    }
  )
}

// delete group of student
export function useDeleteGroupOfStudent(setVisible: (visible: boolean) => void) {
  const qc = useQueryClient()
  return useMutation(
    async (idData: { student_id: number; student_group_id: number }) => {
      const res = await $api.delete(
        studentsEndpoints.INDEX + `?student_group_id=${idData.student_group_id}`
      )
      return res.data.data
    },
    {
      onSuccess: (_, data) => {
        if (Number(data.student_id) === Number(data.student_group_id)) {
          message.success("Guruhdan o'quvchi o'chirildi")
          qc.invalidateQueries([queryNames.STUDENTS])
          setVisible(false)
        } else {
          qc.invalidateQueries([queryNames.ACTIVE_GROUPS])
          qc.invalidateQueries([queryNames.ONE_STUDENT_GROUPS])
          message.success("Guruhdan o'quvchi o'chirildi")
        }
      },
    }
  )
}

// delete student
export function useDeleteStudent() {
  const qc = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(studentsEndpoints.INDEX + `?id=${id}`)
      return res.data.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.STUDENTS])
        message.success("O'quvchi o`chirildi!")
      },
    }
  )
}

// delete attached test
export function useDeleteAttachedTest() {
  const qc = useQueryClient()
  return useMutation(
    async (info: { group_id: number; shablon_id: number }) => {
      const res = await $api.delete(
        attachedTestEndpoints.INDEX +
          `?group_id=${info.group_id}&shablon_id=${info.shablon_id}`
      )
      return res.data.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.ATTACHED_TESTS])
        message.success('Testi o`chirildi')
      },
    }
  )
}

// connect group to student
export function useConnectGroupToStudent(
  setVisibleNewGroup: (visible: boolean) => void
) {
  const qc = useQueryClient()
  return useMutation(
    async (value: { group_id: number; profile_id: number }) => {
      const res = await $api.post(
        studentsEndpoints.CREATE +
          `?group_id=${value.group_id}&profile_id=${value.profile_id}`
      )
      return res.data.data
    },
    {
      onSuccess: () => {
        setVisibleNewGroup(false)
        qc.invalidateQueries([queryNames.ACTIVE_GROUPS])
        qc.invalidateQueries([queryNames.ONE_STUDENT_GROUPS])
      },
    }
  )
}

// create and edit student
export function useCreateEditStudent(setVisible: (visible: boolean) => void) {
  const qc = useQueryClient()
  return useMutation(
    async (data: {
      name: string
      phone: string
      parent_number: string
      group_id?: number
      id?: number
    }) => {
      const res = await $api.post(studentsEndpoints.INDEX, data)
      return res.data.data
    },
    {
      onSuccess: (_, data) => {
        qc.invalidateQueries([queryNames.STUDENTS])
        if (data?.id) {
          message.success("O'quvchi yangilandi!")
        } else {
          message.success("O'quvchi qo'shildi!")
        }
        setVisible(false)
      },
    }
  )
}

//user update information
export function useUpdateUser() {
  const qc = useQueryClient()
  return useMutation(
    async (data: FormData) => {
      const res = await $api.post(myPageEndpoints.INDEX, data)
      return res.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.MY_PAGE])
        message.success("Ma'lumotlar yangilandi")
      },
    }
  )
}

// create and edit test
export function useCreateAndEditTest(push: any) {
  return useMutation(
    async (data: { lesson: string; subject: number }) => {
      const res = await $api.post(testEndpoints.INDEX, data)
      return res.data
    },
    {
      onSuccess: (data) => {
        push(rootPaths.startTest + rootPaths.myTest + '/' + data.id)
        message.success('Test yaratildi!')
      },
    }
  )
}

// create and edit question
export function useCreateAndEditQuestion(
  id: number,
  testChange: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: any
) {
  return useMutation(
    async (data: FormData) => {
      const res = await $api.post(questionEndpoints.INDEX, data)
      return res.data as Question
    },
    {
      onSuccess: (data) => {
        testChange(false)
        dispatch(updateQuestion({ data, id }))
        if (id) message.success('Savol yangilandi!')
        else message.success('Savol yaratildi!')
      },
    }
  )
}

// delete question
export function useDeleteQuestion() {
  return useMutation(async (id: number) => {
    const res = await $api.delete(`${questionEndpoints.INDEX}?id=${id}`)
    return res.data
  })
}

// delete image

export function useDeleteImage() {
  return useMutation(
    async (params: string) => {
      const res = await $api.delete(removeImageEndpoints.INDEX + params)
      return res.data
    },
    {
      onSuccess: () => {
        message.success("Rasm o'chirildi")
      },
    }
  )
}

//import template
export function useImportTemplate(
  setModalData: React.Dispatch<
    React.SetStateAction<{ open: boolean; data: ImportedQuestion[] }>
  >
) {
  return useMutation(
    async (data: FormData) => {
      const res = await $api.post<ImportedQuestion[]>(importEndpoints.INDEX, data)
      return res.data
    },
    {
      onSuccess: (data) => {
        setModalData({
          data: data,
          open: true,
        })
      },
    }
  )
}

//create test from import template
export function useCreateTesFromTemplate(
  test_id: string,
  close: () => void,
  dispatch: any
) {
  return useMutation(
    async (tests: ImportedQuestion[]) => {
      const res = await $api.post<{ id: string; questions: Question[] }>(
        createTestEndpoints.INDEX,
        {
          tests,
          test_id,
        }
      )
      return res.data
    },
    {
      onSuccess: (data) => {
        message.success('Savollar yaratildi!')
        dispatch(andQuestions(data.questions))
        close()
        // qc.invalidateQueries([queryNames.ONE_TEST, test_id])
      },
    }
  )
}

//create block
export function useStartTest(
  setModalVisible: (visible: boolean) => void,
  isThereGroups: boolean
) {
  const qc = useQueryClient()
  const { push } = useHistory()
  return useMutation(
    async (data: any) => {
      const res = await $api.post(blockEndpoints.INDEX, data)
      return res.data
    },
    {
      onSuccess: (_, payload) => {
        qc.invalidateQueries([queryNames.MY_TEST])
        message.success(
          `${
            isThereGroups ? 'Test boshlandi va blok yaratildi!' : 'Blok yaratildi!'
          }`
        )
        if (payload.group_ids.length > 0) {
          if (payload.group_ids.length === 1) {
            push(rootPaths.myGroup + '/' + payload.group_ids[0])
          } else {
            push(rootPaths.myGroup)
          }
        }
        setModalVisible(false)
      },
    }
  )
}

// start virtual test
export const useStartVirtualTest = (form: FormInstance) => {
  const dispatch = useAppDispatch()
  const { setSelectedGroups } = selectedGroupsSlice.actions
  const { push } = useHistory()
  return useMutation(
    async (data: {
      group_ids: number[]
      course_id: number
      start_at: string
      finish_at: string
    }) => {
      const res = await $api.post(virtualTestEndpoints.INDEX, data)
      return res.data
    },
    {
      onSuccess: (preview, payload) => {
        message.success(preview.message)
        if (payload.group_ids.length === 1) {
          push(rootPaths.myGroup + '/' + payload.group_ids[0])
        } else {
          push(rootPaths.myGroup)
        }
        form.resetFields()
        dispatch(setSelectedGroups([]))
      },
    }
  )
}

// start DTM test
export const useStartDTMTest = (form: FormInstance) => {
  const dispatch = useAppDispatch()
  const { setSelectedGroups } = selectedGroupsSlice.actions
  const { push } = useHistory()
  return useMutation(
    async (data: DTMPoyloadData) => {
      const res = await $api.post(dtmTestEndpoints.INDEX, data)
      return res.data
    },
    {
      onSuccess: (preview, payload) => {
        message.success(preview.message)
        if (payload.group_ids.length === 1) {
          push(rootPaths.myGroup + '/' + payload.group_ids[0])
        } else {
          push(rootPaths.myGroup)
        }
        form.resetFields()
        dispatch(setSelectedGroups([]))
      },
    }
  )
}

// send sms
export const useSendSms = (setStep: (step: number) => void) => {
  return useMutation(
    async (data: { phone: string }) => {
      const res = await $api.post(sendSmsEndpoints.INDEX, data)
      return res.data
    },
    {
      onSuccess: () => {
        setStep(2)
        message.success('Telefon raqamingizga tasdiqlash kodi yuborildi!')
      },
    }
  )
}

// send sms
export const useCheckSms = (setStep: (step: number) => void) => {
  const { push } = useHistory()
  return useMutation(
    async (data: { phone: string; code: number }) => {
      const res = await $api.post(checkSmsEndpoints.INDEX, data)
      return res.data
    },
    {
      onSuccess: (preview) => {
        AuthService.setToken(preview.user.token)
        if (preview.message === true) {
          push(rootPaths.myGroup)
        } else {
          setStep(3)
        }
      },
      onError: () => {
        message.error('Tasdiqlash kodi xato!')
      },
    }
  )
}

// save user name
export const useSaveUserName = (setStep: (step: number) => void) => {
  const { push } = useHistory()
  return useMutation(
    async (data: { full_name: string }) => {
      const res = await $api.post(saveUserNameEndpoints.INDEX, data)
      return res.data
    },
    {
      onSuccess: () => {
        push(rootPaths.myGroup)
        setStep(1)
      },
    }
  )
}

// logout
export const useLogout = () => {
  const { push } = useHistory()
  return useMutation(
    async () => {
      const res = await $api.post(logoutEndpoints.INDEX)
      return res.data
    },
    {
      onSuccess: () => {
        push(mainPaths.login)
        AuthService.clearToken()
      },
    }
  )
}
