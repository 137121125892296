import React, { useEffect } from 'react'
import Test from './Test'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import {
  addNewQuestion,
  setQuestionList,
} from '../../../store/reducers/questionListSlice'
import { Button } from 'antd'
import { useOneTest } from '../../../queries/queries'
import { useParams } from 'react-router-dom'

const TestsList = () => {
  const { test_id } = useParams<{ test_id: string }>()
  const { data } = useOneTest(test_id)
  const dispatch = useAppDispatch()
  const questions = useAppSelector(
    (state) => state.questionListSlice.questionsList
  )
  const newItemHas = useAppSelector(
    (state) => state.questionListSlice.isNewItemHas
  )

  useEffect(() => {
    if (data?.questions) {
      if (data.questions.length === 0) {
        dispatch(addNewQuestion())
      } else {
        dispatch(setQuestionList(data.questions))
      }
    }
    // eslint-disable-next-line
  }, [data?.questions])

  const addQuestion = () => {
    dispatch(addNewQuestion())
    // dispatch(changeHasNewItem(true))
  }

  return (
    <div className={'test_list'}>
      {questions.map((question, index) => (
        <Test question={question} key={index} number={index + 1} />
      ))}
      <Button
        onClick={addQuestion}
        disabled={newItemHas}
        className={'add_test_button d-flex justify-center border-5'}
        type={'primary'}
      >
        + test qo'shish
      </Button>
    </div>
  )
}

export default TestsList
