import { ConfigProvider } from 'antd'
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import App from './App'
import Blank from './components/blank/Blank'
import Login from './pages/login/Login'
import { mainPaths } from './routing/rootPaths'
import CheckLogIn from './services/CheckLogin'
import { setupStore } from './store/store'

const store = setupStore()
const client = new QueryClient()

ConfigProvider.config({
  theme: {
    primaryColor: '#0c4292',
  },
})

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <QueryClientProvider client={client}>
        <Switch>
          <Route path={mainPaths.login}>
            <Login />
          </Route>
          <Route path={mainPaths.content} exact={true}>
            <Blank />
          </Route>
          <CheckLogIn>
            <App />
          </CheckLogIn>
        </Switch>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
)
