import { LocalStorage } from './LocalStorage'
import { TOKEN_KEY, USER_KEY } from '../utils/constants/localStorageKeys'
import { User } from '../models/user/User'
import { mainPaths } from '../routing/rootPaths'

export const AuthService = {
  clearToken() {
    LocalStorage.remove(TOKEN_KEY)
    window.location.href = mainPaths.login
  },

  checkLogin() {
    const token = localStorage.getItem(TOKEN_KEY)
    if (token) return true
    else return false
  },

  getUser(): User | undefined {
    return LocalStorage.get<User>(USER_KEY)
  },

  setToken(token: string) {
    LocalStorage.set(TOKEN_KEY, token)
  },

  getToken(): string | undefined {
    return LocalStorage.get<string>(TOKEN_KEY)
  },
}
