import React from 'react'

const Garbage = () => {
  return (
    <svg
      width="27"
      height="35"
      viewBox="0 0 27 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 30.625C2.25 32.6875 3.9375 34.375 6 34.375H21C23.0625 34.375 24.75 32.6875 24.75 30.625V8.125H2.25V30.625ZM26.625 2.5H20.0625L18.1875 0.625H8.8125L6.9375 2.5H0.375V6.25H26.625V2.5Z"
        fill="#F54848"
      />
    </svg>
  )
}

export default Garbage;
