import React, { useEffect, useState } from 'react'
import { Empty, Image } from 'antd'
import { QuestionEnum } from './Test'
import CancelIcon from '../../../assets/icons/CancelIcon'

interface ImagePopoverInterface {
  image?: string | File
  type: QuestionEnum
  handleImageRemove: (type: QuestionEnum) => void
}

const ImagePopover: React.FC<ImagePopoverInterface> = ({
  image,
  handleImageRemove,
  type,
}) => {
  const [imageSrc, setImageSrc] = useState('')

  useEffect(() => {
    if (image && typeof image === 'string') {
      setImageSrc(image)
      return
    }
    if (image) {
      setImageSrc(URL.createObjectURL(image as File))
    }
  }, [image])

  const deleteImage = () => {
    handleImageRemove(type)
  }

  if (!image) return <Empty description={'Rasm kiritilmagan!'} />

  return (
    <div className={'image_popover'}>
      <Image style={{ width: '200px' }} src={imageSrc} />
      <span
        onClick={deleteImage}
        title={"Rasmni o'chirish"}
        className={'image_popover_close'}
      >
        <CancelIcon />
      </span>
    </div>
  )
}

export default ImagePopover
