import { Pagination, Popconfirm, Popover, Select, Table } from "antd";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import MoreIcon from "../../assets/icons/MoreIcon";
import { StudentData } from "../../models/detailedGroup/StudentsData";
import { useDeleteStudent } from "../../queries/mutation";
import { useStudents } from "../../queries/queries";
import { queryNames } from "../../queries/queryNames";

interface ModalData {
  type: string;
  student_id?: number;
}

interface IProps {
  id: number;
  setModalVisible: (visible: boolean) => void;
  searchText: string;
  setModalData: (data: ModalData) => void;
}

const StudentsOfGroup: React.FC<IProps> = ({
  id,
  setModalVisible,
  searchText,
  setModalData,
}) => {
  const { Column } = Table;
  const queryClient = useQueryClient();
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const deleteStudentMutation = useDeleteStudent();
  const { data, isLoading } = useStudents(id, page, pageSize, searchText);

  // edit student
  const editStudent = (record: StudentData) => {
    queryClient.invalidateQueries([queryNames.ONE_STUDENT_INFO]);
    queryClient.invalidateQueries([queryNames.ONE_STUDENT_GROUPS]);
    setModalData({ type: "edit", student_id: record.id });
    setModalVisible(true);
  };

  // delete student
  const deleteStudent = (record: StudentData) => {
    deleteStudentMutation.mutate(record.id);
  };

  // closeModal
  const closeModal = () => {
    setModalVisible(false);
  };

  // pagination
  const isPagingData = () => {
    if (data && data.count > 10) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Table
        className="students_table"
        dataSource={data?.students}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record.id}
      >
        <Column align="left" title="O'quvchi" dataIndex="name" key="name" />
        <Column
          align="center"
          title="Telefon raqam"
          dataIndex="number"
          key="number"
        />
        <Column
          align="center"
          className="status"
          title="Status"
          key="status"
          render={(record) => (
            <p className={record.status}>&#9679; {record.status}</p>
          )}
        />
        <Column
          align="center"
          className="more"
          render={(record) => (
            <Popover
              overlayClassName="more_popover"
              placement="left"
              trigger="hover click"
              mouseEnterDelay={2}
              mouseLeaveDelay={2}
              content={() => (
                <div className="more_popover_actions">
                  <p onClick={() => editStudent(record)}>Tahrirlash</p>
                  <Popconfirm
                    title="O'quvchini o'chirmoqchimisiz?"
                    onConfirm={() => deleteStudent(record)}
                    okText="Ha"
                    cancelText="Yo'q"
                    placement="left"
                    trigger="hover click"
                    mouseEnterDelay={2}
                    mouseLeaveDelay={2}
                  >
                    <p onClick={closeModal}>O'chirish</p>
                  </Popconfirm>
                </div>
              )}
            >
              <div className="more_icon">
                <MoreIcon />
              </div>
            </Popover>
          )}
        />
      </Table>
      {isPagingData() && (
        <div className="pagination">
          <div className="pageSize">
            <p>Har bir sahifadagi qatorlar soni :</p>
            <Select
              value={pageSize}
              onSelect={(pageSize) => setPageSize(pageSize)}
            >
              <Select.Option value="10">10</Select.Option>
              <Select.Option value="20">20</Select.Option>
              <Select.Option value="50">50</Select.Option>
            </Select>
          </div>
          <Pagination
            total={Number(data?.count) || 10}
            current={+(data?.current_page || 1)}
            pageSize={pageSize}
            onChange={(page) => setPage(page)}
            showSizeChanger={false}
          />
        </div>
      )}
    </>
  );
};

export default StudentsOfGroup;
