import { addControllerPrefix } from '../helpers/addControllerPrefix'

export const controllers = {
  SEND_SMS: '/sendsms',
  CHECK_SMS: '/checksms',
  SAVE_USER_NAME: '/profile',
  IS_AUTH: '/auth',
  TEACHER_GROUP: '/group',
  STUDENTS: '/student-group',
  TESTS: '/test',
  ATTACHED_TESTS: '/group-test',
  MY_PAGE: '/mypage',
  ADDRESS: '/address',
  DIRECTION: '/direction',
  SUBJECT_TEST: '/subject-test',
  SUBJECT: '/subject',
  QUESTION: '/question',
  YEARS: '/year',
  REMOVE_IMAGE: '/remove-image',
  TEMPLATE: 'https://back2.test.aim.uz/frontend/web/Shablon.docx',
  IMPORT: '/import',
  CREATE_TEST: '/createtest',
  AIM_TEST: '/aim-test',
  AIM_SUBJECTS: '/aim-subject',
  BLOCKS: '/shablon',
  VIRTUAL_TEST: '/virtual',
  DTM_TEST: '/dtm',
  LOGOUT: '/logout',
}

// send sms
const send_sms = {
  INDEX: '',
}
export const sendSmsEndpoints: typeof send_sms = addControllerPrefix(
  send_sms,
  controllers.SEND_SMS
)

// send sms
const check_sms = {
  INDEX: '',
}
export const checkSmsEndpoints: typeof check_sms = addControllerPrefix(
  check_sms,
  controllers.CHECK_SMS
)

// save user name
const save_user_name = {
  INDEX: '',
}
export const saveUserNameEndpoints: typeof save_user_name = addControllerPrefix(
  save_user_name,
  controllers.SAVE_USER_NAME
)

// is auth
const is_auth = {
  INDEX: '',
}
export const isAuthEndpoints: typeof is_auth = addControllerPrefix(
  is_auth,
  controllers.IS_AUTH
)

// group
const teacher_group = {
  INDEX: '',
}

export const teacherGroupEndpoints: typeof teacher_group = addControllerPrefix(
  teacher_group,
  controllers.TEACHER_GROUP
)

// students
const students = {
  INDEX: '',
  CREATE: '-create',
}
export const studentsEndpoints: typeof students = addControllerPrefix(
  students,
  controllers.STUDENTS
)

// test
const tests = {
  INDEX: '',
}

export const testEndpoints: typeof tests = addControllerPrefix(
  tests,
  controllers.TESTS
)
//block
const block = {
  INDEX: '',
}
export const blockEndpoints: typeof block = addControllerPrefix(
  block,
  controllers.BLOCKS
)

// virtual tests
const virtual = {
  INDEX: '',
}
export const virtualTestEndpoints: typeof virtual = addControllerPrefix(
  virtual,
  controllers.VIRTUAL_TEST
)

// DTM tests
const dtm = {
  INDEX: '',
}
export const dtmTestEndpoints: typeof dtm = addControllerPrefix(
  dtm,
  controllers.DTM_TEST
)

// attached tests
const attached_tests = {
  INDEX: '',
}
export const attachedTestEndpoints: typeof attached_tests = addControllerPrefix(
  attached_tests,
  controllers.ATTACHED_TESTS
)

//profil page
const mypage = {
  INDEX: '',
}
export const myPageEndpoints: typeof mypage = addControllerPrefix(
  mypage,
  controllers.MY_PAGE
)

//address
const address = {
  INDEX: '',
}
export const addressEndpoints: typeof address = addControllerPrefix(
  address,
  controllers.ADDRESS
)

//direction
const direction = {
  INDEX: '',
}
export const directionEndpoints: typeof direction = addControllerPrefix(
  direction,
  controllers.DIRECTION
)

//subject-test
const subjectTest = {
  INDEX: '',
}
export const subjectTestEndpoints: typeof subjectTest = addControllerPrefix(
  subjectTest,
  controllers.SUBJECT_TEST
)
//subject
const subject = {
  INDEX: '',
}
export const subjectEndpoints: typeof subject = addControllerPrefix(
  subject,
  controllers.SUBJECT
)

//question
const question = {
  INDEX: '',
}
export const questionEndpoints: typeof question = addControllerPrefix(
  question,
  controllers.QUESTION
)

//remove image
const remove_image = {
  INDEX: '',
}
export const removeImageEndpoints: typeof remove_image = addControllerPrefix(
  remove_image,
  controllers.REMOVE_IMAGE
)

//years
const years = {
  INDEX: '',
}
export const yearsEndpoints: typeof years = addControllerPrefix(
  years,
  controllers.YEARS
)

//import
const importTemplate = {
  INDEX: '',
}
export const importEndpoints: typeof importTemplate = addControllerPrefix(
  importTemplate,
  controllers.IMPORT
)

//create_test
const createTest = {
  INDEX: '',
}
export const createTestEndpoints: typeof createTest = addControllerPrefix(
  createTest,
  controllers.CREATE_TEST
)

//aim tests
const aimTests = {
  INDEX: '',
}
export const aimTestsEndpoints: typeof aimTests = addControllerPrefix(
  aimTests,
  controllers.AIM_TEST
)

const aimSubjects = {
  INDEX: '',
}
export const aimSubjectsEndpoints: typeof aimSubjects = addControllerPrefix(
  aimSubjects,
  controllers.AIM_SUBJECTS
)

// logout
const logout = {
  INDEX: '',
}
export const logoutEndpoints: typeof logout = addControllerPrefix(
  logout,
  controllers.LOGOUT
)
