import React from 'react'

const FileText = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7498 21H5.24902C5.05011 21 4.85935 20.921 4.71869 20.7803C4.57804 20.6397 4.49902 20.4489 4.49902 20.25V3.75C4.49902 3.55109 4.57804 3.36032 4.71869 3.21967C4.85935 3.07902 5.05011 3 5.24902 3H14.2498L19.4998 8.25V20.25C19.4998 20.3485 19.4804 20.446 19.4427 20.537C19.405 20.628 19.3497 20.7107 19.2801 20.7803C19.2104 20.85 19.1278 20.9052 19.0368 20.9429C18.9458 20.9806 18.8482 21 18.7498 21Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 3V8.25H19.5007"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.75H15"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15.75H15"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FileText
