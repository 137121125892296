import { Avatar, Popover } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
// import { useDispatch } from 'react-redux'
import Hamburger from '../../assets/icons/Hamburger'
import LogoutIcon from '../../assets/icons/LogoutIcon'
import RedirectIcon from '../../assets/icons/RedirectIcon'
import UserIcon from '../../assets/icons/UserIcon'
import { useAppDispatch } from '../../hooks/redux'
import { useLogout } from '../../queries/mutation'
import { useMyPage } from '../../queries/queries'
import { rootPaths } from '../../routing/rootPaths'
import { userSlice } from '../../store/reducers/UserSlice'
import { URLS } from '../../utils/constants/urls'
import './Header.scss'

const Header = () => {
  // const { menuRes } = useAppSelector((state) => state.userReducer)
  const { data, isLoading } = useMyPage()
  const { menuResponsive } = userSlice.actions
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const logoutMutation = useLogout()

  const handleMenuRes = () => {
    dispatch(menuResponsive())
  }

  // to profile page
  const toProfile = () => {
    push(rootPaths.myPage)
  }

  // to test aim
  const toTestAim = () => {
    window.location.href = URLS.TEST_AIM_UZ
  }

  // logout
  const logout = () => {
    logoutMutation.mutate()
  }

  return (
    <div className="header">
      <div className="header_left">
        <div
          style={{ cursor: 'pointer' }}
          className="hamburger"
          onClick={handleMenuRes}
        >
          <Hamburger />
        </div>
        <div className="logo d-flex">
          {/* <LOGO /> */}
          <h1>Akam teacher</h1>
        </div>
      </div>
      <Popover
        overlayClassName="user_popover"
        placement="bottomLeft"
        trigger="hover click"
        mouseEnterDelay={2}
        mouseLeaveDelay={2}
        content={() => (
          <div className="user_popover_content">
            <p onClick={toProfile}>
              <UserIcon />
              <span>Profil</span>
            </p>
            <p onClick={toTestAim}>
              <RedirectIcon />
              <span>O'quvchi bo'lib kirish</span>
            </p>
            <p onClick={logout}>
              <LogoutIcon />
              <span>Chiqish</span>
            </p>
          </div>
        )}
      >
        {isLoading || !data?.image ? (
          <Avatar size={54} icon={<UserIcon />} />
        ) : (
          <img src={data?.image} alt="user" className="user_img" />
        )}
      </Popover>
    </div>
  )
}

export default Header
