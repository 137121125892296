import { Button, Form, Input, Pagination, Select, Table } from "antd";
// import dayjs from "dayjs";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CancelIcon from "../../assets/icons/CancelIcon";
import ClockIcon from "../../assets/icons/ClockIcon";
import SearchIcon from "../../assets/icons/SearchIcon";
import { TestingStudentsData } from "../../models/detailedGroup/TestingStudentsData";
import { useTestingStudents } from "../../queries/queries";
import UniversalHeader from "../common/universal-header/UniversalHeader";
import CountDown from "./CountDown";

const TestingStudents: React.FC = () => {
  const {
    group_id,
    template_id,
  }: {
    group_id: string;
    template_id: string;
  } = useParams();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");
  const { data, isLoading } = useTestingStudents(
    Number(group_id),
    Number(template_id),
    page,
    pageSize,
    searchText
  );
  const [form] = Form.useForm();
  const { Column } = Table;
  const timerRef = React.useRef<any>();

  // input suffix
  const inputSuffix = () => {
    const onCancel = () => {
      setSearchText("");
      form.resetFields();
    };
    if (searchText) {
      return (
        <span className="suffix" onClick={onCancel}>
          <CancelIcon />
        </span>
      );
    } else {
      return <SearchIcon />;
    }
  };

  // uzb time
  // console.log(
  //   dayjs
  //     .unix(
  //       new Date().getTime() / 1000 +
  //         new Date().getTimezoneOffset() * 60 +
  //         300 * 60
  //     )
  //     .format("DD.MM.YYYY | hh-mm-ss")
  // );

  // pagination
  const isPagingData = () => {
    if (data && data.count > 10) {
      return true;
    } else {
      return false;
    }
  };

  // const timeSpent = (time: number) => {

  // }

  return (
    <div className="component_container testingStudents">
      <UniversalHeader
        backBtn={true}
        title={data?.shablon_name || ""}
        button={
          <div className="studentHeader">
            <Form form={form}>
              <Form.Item name="searchText" className="search">
                <Input
                  bordered={false}
                  placeholder="Qidirish"
                  suffix={inputSuffix()}
                  onChange={(e) => {
                    clearTimeout(timerRef.current);
                    timerRef.current = setTimeout(() => {
                      setSearchText(
                        e.target.value.length > 2 ? e.target.value : ""
                      );
                    }, 500);
                  }}
                />
              </Form.Item>
            </Form>
            <Button className="white_blue_btn border-5">
              {data?.finish_student_count || 0}/{data?.count || 0}
            </Button>
            {data?.test_finish_at! * 1000 < new Date().getTime() ? null : (
              <Button className="blue_ant_button border-5" type="primary">
                <ClockIcon /> <CountDown finish_time={data?.test_finish_at} />
              </Button>
            )}
          </div>
        }
      />

      <Table
        dataSource={data?.students}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record.student_id}
        className="testingStudents"
      >
        <Column align="left" title="O'quvchi" dataIndex="student_name" />
        <Column
          align="center"
          title="Ball"
          render={(record: TestingStudentsData) => (
            <span>
              {record.answer_count}/{record.question_count} ball
            </span>
          )}
        />
        <Column
          align="center"
          title="Sarflagan vaqti"
          dataIndex="time_spent"
          render={(time) => {
            return <>{Math.ceil(time / 60)} minut</>;
          }}
          key="time_spent"
        />
        <Column
          align="center"
          title="Status"
          className="status"
          render={(record: TestingStudentsData) => (
            <span className={record.status}>&#9679; {record.status}</span>
          )}
        />
      </Table>
      {isPagingData() && (
        <div className="pagination">
          <div className="pageSize">
            <p>Har bir sahifadagi qatorlar soni :</p>
            <Select
              value={pageSize}
              onSelect={(pageSize) => setPageSize(pageSize)}
            >
              <Select.Option value="10">10</Select.Option>
              <Select.Option value="20">20</Select.Option>
              <Select.Option value="50">50</Select.Option>
            </Select>
          </div>
          <Pagination
            total={Number(data?.count) || 10}
            pageSize={pageSize}
            current={+(data?.current_page || 1)}
            onChange={(page) => setPage(page)}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default TestingStudents;
