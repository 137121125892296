import React from 'react'
import './Navbar.scss'
import { useAppSelector } from '../../hooks/redux'
import { NavLink } from 'react-router-dom'
import { rootPaths } from '../../routing/rootPaths'
import MyGroupIcon from '../../assets/icons/MyGroupIcon'
import TestsIcon from '../../assets/icons/TestsIcon'
import { useMyPage } from '../../queries/queries'
import { Spin } from 'antd'

const Navbar = () => {
  const { data, isLoading } = useMyPage()
  const menuItems = [
    {
      icon: MyGroupIcon,
      title: 'Guruhlarim',
      path: rootPaths.myGroup,
      exact: false,
    },
    {
      icon: TestsIcon,
      title: 'Testlar',
      path: rootPaths.startTest,
      exact: false,
    },
    // {
    //   icon: CloudCheck,
    //   title: 'Mening testlarim',
    //   titleIn: 'Mavzuli testlar',
    //   path: rootPaths.myTest,
    //   exact: true,
    // },
    // {
    //   icon: TestProcessIcon,
    //   title: "Test jarayoni",
    //   path: rootPaths.testProcess,
    //   exact: true,
    // },
  ]

  const { menuRes } = useAppSelector((state) => state.userReducer)
  return (
    <div className={`${menuRes ? '' : 'active'} navbar`}>
      <div className="navbarImage">
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <img src={data?.image} alt="profileImage" />
        )}
      </div>
      {menuRes && (
        <div className="teacherName">
          <h1>{data?.full_name || ''}</h1>
          <p>o'qituvchi</p>
        </div>
      )}

      {menuItems.map((item, index) => (
        <NavLink
          to={item.path}
          className="navbar_menu"
          key={index}
          exact={item.exact}
        >
          {/* {menuRes && ( */}
          <ul key={index}>
            <item.icon />
            <li>{item.title}</li>
          </ul>
          {/* )} */}
        </NavLink>
      ))}
    </div>
  )
}

export default Navbar
