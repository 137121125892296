import React from 'react'

const ClockIcon: React.FC = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.49 2.5C6.97 2.5 2.5 6.98 2.5 12.5C2.5 18.02 6.97 22.5 12.49 22.5C18.02 22.5 22.5 18.02 22.5 12.5C22.5 6.98 18.02 2.5 12.49 2.5ZM12.5 20.5C8.08 20.5 4.5 16.92 4.5 12.5C4.5 8.08 8.08 4.5 12.5 4.5C16.92 4.5 20.5 8.08 20.5 12.5C20.5 16.92 16.92 20.5 12.5 20.5ZM13 7.5H11.5V13.5L16.75 16.65L17.5 15.42L13 12.75V7.5Z"
        fill="white"
      />
    </svg>
  )
}

export default ClockIcon
