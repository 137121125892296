import { Tabs } from 'antd'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import StudentsHeader from '../../components/detailedGroup/StudentsHeader'
import StudentsModal from '../../components/detailedGroup/StudentsModal'
import StudentsOfGroup from '../../components/detailedGroup/StudentsOfGroup'
import AttachedTests from '../../components/detailedGroup/AttachedTests'

import './DetailedGroup.scss'
import { useOneGroup } from '../../queries/queries'

interface ModalData {
  type: string
  student_id?: number
}

const DetailedGroup: React.FC = () => {
  const { group_id: id } = useParams<{
    group_id: string
  }>()
  const [searchText, setSearchText] = useState<string>('')
  const [modalVisible, setModalVisible] = useState(false)
  const [modalData, setModalData] = useState<ModalData>({ type: '' })
  const [defaultActiveKey, setDefaultActiveKey] = useState('attached_tests')
  const { data } = useOneGroup(Number(id))
  const { TabPane } = Tabs

  return (
    <div className="component_container students">
      <StudentsHeader
        title={data?.name || ''}
        setModalVisible={setModalVisible}
        setModalData={setModalData}
        setSearchText={setSearchText}
        searchText={searchText}
        group_link={data?.link || ''}
        setDefaultActiveKey={setDefaultActiveKey}
      />
      <Tabs
        className="studentsTab"
        activeKey={defaultActiveKey}
        onChange={(key) => setDefaultActiveKey(key)}
      >
        <TabPane tab="Biriktirilgan testlar" key={'attached_tests'}>
          <AttachedTests group_id={Number(id)} searchText={searchText} />
        </TabPane>
        <TabPane tab="O'quvchilar" key={'students'}>
          <StudentsOfGroup
            id={Number(id)}
            setModalVisible={setModalVisible}
            searchText={searchText}
            setModalData={setModalData}
          />
        </TabPane>
      </Tabs>
      <StudentsModal
        visible={modalVisible}
        setVisible={setModalVisible}
        group_id={Number(id)}
        data={modalData}
      />
    </div>
  )
}

export default DetailedGroup
