import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
  selectedGroups: number[]
}

const initialState: IInitialState = {
  selectedGroups: [],
}
export const selectedGroupsSlice = createSlice({
  name: 'selectedGroups',
  initialState,
  reducers: {
    setSelectedGroups(state, action: PayloadAction<number[]>) {
      state.selectedGroups = action.payload
    },
  },
})

export default selectedGroupsSlice.reducer
