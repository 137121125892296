import React, { useState, useRef, useEffect, useCallback } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

const CountDown = ({ finish_time }) => {
  const [timerDays, setTimerDays] = useState('00')
  const [timerHours, setTimerHours] = useState('00')
  const [timerMinutes, setTimerMinutes] = useState('00')
  const [timerSeconds, setTimerSeconds] = useState('00')

  let interval = useRef()

  const startTimer = useCallback(() => {
    const countdownDate = finish_time * 1000
    interval.current = setInterval(() => {
      const now = new Date().getTime()
      const distance = countdownDate - now

      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      if (distance < 0) {
        clearInterval(interval.current)
      } else {
        setTimerDays(String(days))
        setTimerHours(String(hours))
        setTimerMinutes(String(minutes))
        setTimerSeconds(String(seconds))
      }
    }, 1000)
  }, [finish_time])

  useEffect(() => {
    if (finish_time) {
      startTimer()
    }

    const current = interval.current
    return () => {
      clearInterval(current)
    }
  }, [finish_time, startTimer])

  const isEmptyTimer = () => {
    if (timerDays + timerHours + timerMinutes + timerSeconds > 0) {
      return false
    } else {
      return true
    }
  }

  return (
    <div>
      {isEmptyTimer() ? <LoadingOutlined /> : null}
      {timerDays > 0 && (
        <span>
          {timerDays}
          kun
        </span>
      )}
      {timerHours > 0 && <span>{timerHours}s : </span>}
      <span>{timerMinutes}d : </span>
      <span>{timerSeconds}s</span>
    </div>
  )
}

export default CountDown
