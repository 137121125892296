import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import UniversalHeader from '../../components/common/universal-header/UniversalHeader'
import CreateTestInfo from '../../components/tests/editTest/CreateTestInfo'
import '../../components/tests/editTest/editTest.scss'
import { useOneTest } from '../../queries/queries'
import { Spin } from 'antd'
import EditTestBody from '../../components/tests/editTest/EditTestBody'
import { backToInitialState } from '../../store/reducers/questionListSlice'
import { useAppDispatch } from '../../hooks/redux'

const EditTest = () => {
  const { test_id } = useParams<{ test_id: string }>()
  const { data, isLoading, isFetching } = useOneTest(test_id)

  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(backToInitialState())
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className={'component_container'}>
      <Spin spinning={isLoading || isFetching}>
        <UniversalHeader title={"Test qo'shish"} />
        <CreateTestInfo
          id={test_id}
          data={{ subject: data?.subject_name, lesson: data?.test_name }}
        />
        <EditTestBody test_id={test_id} />
      </Spin>
    </div>
  )
}

export default EditTest
