import React, { ChangeEvent, useRef, useState } from 'react'
import { Button, message } from 'antd'
import Download from '../../../assets/icons/Download'
import Import from '../../../assets/icons/Import'
import { controllers } from '../../../utils/constants/api'
import { useImportTemplate } from '../../../queries/mutation'
import ShowImportedDataModal from './ShowImportedDataModal'
import { ImportedQuestion } from '../../../models/test-page/Question'

const ImportExcel = () => {
  const fileInput = useRef<HTMLInputElement | undefined>()
  const [modalData, setModalData] = useState<{
    open: boolean
    data: ImportedQuestion[]
  }>({
    open: false,
    data: [],
  })

  const importMutation = useImportTemplate(setModalData)

  const download = () => {
    const link = document.createElement('a')
    link.href = controllers.TEMPLATE
    link.setAttribute('download', 'Shablon.docx')
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files?.length === 1) {
      const formData = new FormData()
      formData.append('file', files[0])
      importMutation.mutate(formData)
    } else {
      message.error(`Bittadan ko'p fayl tanlangan!`)
    }
  }

  const closeModal = () => {
    setModalData({
      open: false,
      data: [],
    })
  }

  return (
    <div className={'import_cont'}>
      <Button
        onClick={download}
        icon={<Download />}
        className={'white_blue_btn border-5 btn'}
      >
        Shablon yuklab olish
      </Button>
      {!importMutation.isLoading && (
        <input
          accept={'.doc,.docx'}
          ref={fileInput as any}
          type={'file'}
          onChange={handleChangeInput}
          style={{ display: 'none' }}
        />
      )}
      <Button
        loading={importMutation.isLoading}
        onClick={() => {
          fileInput.current?.click()
        }}
        icon={<Import />}
        className={'white_blue_btn border-5 btn'}
      >
        Shablondan import qilish
      </Button>
      <p>
        Testlarni import qilishdan oldin
        <span>“Shablon yuklab olish”</span>
        tugmasi orqali testlarni shablon asosida shakllantirilganini tekshiring!
      </p>
      <ShowImportedDataModal
        data={modalData.data}
        open={modalData.open}
        close={closeModal}
      />
    </div>
  )
}

export default ImportExcel
