import { Button, Form, FormInstance, Input } from 'antd'
import React from 'react'
import PlusCircleIcon from '../../assets/icons/PlusCircleIcon'
import SearchIcon from '../../assets/icons/SearchIcon'
import UniversalHeader from '../common/universal-header/UniversalHeader'
import { useHistory } from 'react-router-dom'
import { rootPaths } from '../../routing/rootPaths'
import CancelIcon from '../../assets/icons/CancelIcon'

interface TestHeaderProps {
  showCreateButton: boolean
  searchText: string
  setSearchText: (text: string) => void
  form: FormInstance<any> | undefined
  showSearch: boolean
}

const TestsHeader: React.FC<TestHeaderProps> = ({
  showCreateButton,
  searchText,
  setSearchText,
  form,
  showSearch,
}) => {
  const { push } = useHistory()

  const timerRef = React.useRef<any>()

  const createTest = () => {
    push(`${rootPaths.startTest}${rootPaths.myTest}/new`)
  }

  // input suffix
  const inputSuffix = () => {
    const onCancel = () => {
      setSearchText('')
      form?.resetFields()
    }
    if (searchText) {
      return (
        <span className="suffix" onClick={onCancel}>
          <CancelIcon />
        </span>
      )
    } else {
      return <SearchIcon />
    }
  }

  return (
    <div>
      <UniversalHeader
        title="Test o'tkazish"
        button={
          <>
            {showSearch && (
              <Form form={form}>
                <Form.Item name="searchText" className="search">
                  <Input
                    bordered={false}
                    placeholder="Qidirish"
                    suffix={inputSuffix()}
                    onChange={(e) => {
                      clearTimeout(timerRef.current)
                      timerRef.current = setTimeout(() => {
                        setSearchText(
                          e.target.value.length > 2 ? e.target.value : ''
                        )
                      }, 500)
                    }}
                  />
                </Form.Item>
              </Form>
            )}
            {showCreateButton && (
              <Button
                onClick={createTest}
                className="blue_ant_button"
                type="primary"
              >
                <PlusCircleIcon />
                Test qo'shish
              </Button>
            )}
          </>
        }
      />
    </div>
  )
}

export default TestsHeader
