import React from 'react'
import { Col, Empty, Row } from 'antd'
import ImportExcel from './ImportExcel'
import TestsList from './TestsList'

interface EditTestBodyProps {
  test_id: string
}

const EditTestBody: React.FC<EditTestBodyProps> = ({ test_id }) => {
  if (test_id === 'new')
    return (
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        style={{ paddingTop: '4rem' }}
        imageStyle={{
          height: '10rem',
        }}
        description={<p className={'empty_title'}>Test yaratilmagan</p>}
      />
    )

  return (
    <Row>
      <Col span={16}>
        <TestsList />
      </Col>
      <Col span={8}>
        <ImportExcel />
      </Col>
    </Row>
  )
}

export default EditTestBody
