import { Button, Form, Input } from 'antd'
import React from 'react'
import CancelIcon from '../../assets/icons/CancelIcon'
import PlusCircleIcon from '../../assets/icons/PlusCircleIcon'
import SearchIcon from '../../assets/icons/SearchIcon'
import { GroupData } from '../../models/group-page/GroupData'
import UniversalHeader from '../common/universal-header/UniversalHeader'

interface Props {
  setModalVisible: (visible: boolean) => void
  setModalData: (data: { type: string; data?: GroupData }) => void
  setSearchText: (text: string) => void
  searchText: string
}

const MyGroupHeader: React.FC<Props> = ({
  setModalVisible,
  setModalData,
  setSearchText,
  searchText,
}) => {
  const timerRef = React.useRef<any>()
  const [form] = Form.useForm()

  // open modal
  const openModal = () => {
    setModalData({ type: 'create' })
    setModalVisible(true)
  }

  // input suffix
  const inputSuffix = () => {
    const onCancel = () => {
      setSearchText('')
      form.resetFields()
    }
    if (searchText) {
      return (
        <span className="suffix" onClick={onCancel}>
          <CancelIcon />
        </span>
      )
    } else {
      return <SearchIcon />
    }
  }

  return (
    <UniversalHeader
      title="Guruhlar"
      button={
        <>
          <Form form={form}>
            <Form.Item name="searchText" className="search">
              <Input
                bordered={false}
                placeholder="Qidirish"
                suffix={inputSuffix()}
                onChange={(e) => {
                  clearTimeout(timerRef.current)
                  timerRef.current = setTimeout(() => {
                    setSearchText(e.target.value.length > 2 ? e.target.value : '')
                  }, 500)
                }}
              />
            </Form.Item>
          </Form>
          <Button className="blue_ant_button" onClick={openModal} type="primary">
            <PlusCircleIcon />
            Guruh yaratish
          </Button>
        </>
      }
    />
  )
}

export default MyGroupHeader
