export const rootPaths = {
  myGroup: '/mening-guruhlarim',
  detailedGroup: '/mening-guruhlarim/:group_id',
  testingStudents: '/mening-guruhlarim/:group_id/:template_id',
  startTest: '/testlar',
  myTest: '/mening-testlarim',
  aimTests: '/aim-testlari',
  blocks: '/bloklar',
  virtualExam: '/virtual-imtihon',
  dtmTest: '/dtm-test',
  testProcess: '/test-jarayoni',
  myPage: '/mening-sahifam',
  editTest: '/mening-testlarim/:test_id',
}

export const mainPaths = {
  login: '/login',
  content: '/',
}
