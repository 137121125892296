import React from "react";

export default function RightDownIcon() {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.727679 11.3351L6.33437 5.72841L0.727783 0.121826L0.727679 11.3351Z"
        fill="#0C4192"
      />
    </svg>
  );
}
