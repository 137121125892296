import { Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import {
  useConnectGroupToStudent,
  useCreateEditStudent,
  useDeleteGroupOfStudent,
} from '../../queries/mutation'
import {
  useActiveGroups,
  useOneStudentGroups,
  useOneStudentInfo,
} from '../../queries/queries'
import Trash from '../common/svg/Trash'
import CheckIcon from '../../assets/icons/CheckIcon'

interface Props {
  visible: boolean
  setVisible: (visible: boolean) => void
  group_id: number
  data?: {
    type: string
    student_id?: number
  }
}

const StudentsModal: React.FC<Props> = ({ visible, setVisible, group_id, data }) => {
  const [form] = Form.useForm()
  const { Item } = Form
  const createEditStudent = useCreateEditStudent(setVisible)
  const {
    data: studentInfo,
    isLoading: studentInfoLoading,
    isFetching: studentInfoFetching,
  } = useOneStudentInfo(data?.student_id!)
  const {
    data: studentGroups,
    isLoading: studentGroupsLoading,
    isFetching: studentGroupsFetching,
  } = useOneStudentGroups(data?.student_id!)
  const deleteGroupOfStudent = useDeleteGroupOfStudent(setVisible)
  const { data: activeGroups } = useActiveGroups(studentInfo?.profile_id!)
  const [visibleNewGroup, setVisibleNewGroup] = useState(false)
  const connectGroupToStudent = useConnectGroupToStudent(setVisibleNewGroup)
  const [newGroup, setNewGroup] = useState<number>()

  useEffect(() => {
    if (studentInfo && data?.type === 'edit') {
      let parentPhone
      if (studentInfo.parent_number === '0') {
        parentPhone = ''
      } else {
        parentPhone = studentInfo.parent_number
      }
      form.setFieldsValue({
        name: studentInfo.name,
        phone: studentInfo.number,
        parent_number: parentPhone,
      })
    }
  }, [data, form, studentInfo])

  // on cancel
  const onCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  // handle submit
  const handleSubmit = (fields: {
    name: string
    phone: string
    parent_number: string
  }) => {
    if (data?.type === 'create') {
      createEditStudent.mutate({
        name: fields.name,
        phone: fields.phone?.replace(/\s/g, ''),
        parent_number: fields.parent_number?.replace(/\s/g, ''),
        group_id,
      })
    } else {
      createEditStudent.mutate({
        name: fields.name,
        phone: fields.phone?.replace(/\s/g, ''),
        parent_number: fields.parent_number?.replace(/\s/g, ''),
        id: data?.student_id,
      })
    }
  }

  // modal title
  const modalTitle = () => {
    if (data?.type === 'create') {
      return "O'quvchi qo'shish"
    } else if (data?.type === 'edit') {
      return "O'quvchi tahrirlash"
    }
  }

  // submit button text
  const submitButtonText = () => {
    if (data?.type === 'create') {
      return "Qo'shish"
    } else if (data?.type === 'edit') {
      return 'Saqlash'
    }
  }

  // delete group
  const deleteGroup = (student_group_id: number) => {
    const idData = {
      student_group_id,
      student_id: data?.student_id!,
    }
    deleteGroupOfStudent.mutate(idData)
  }

  // after close modal
  const afterCloseModal = () => {
    form.resetFields()
    setVisibleNewGroup(false)
  }

  // handle new group
  const handleNewGroup = (group_id: number) => {
    setNewGroup(group_id)
  }

  // add new group
  const addNewGroup = () => {
    const data = {
      group_id: newGroup!,
      profile_id: studentInfo?.profile_id!,
    }
    connectGroupToStudent.mutate(data)
  }

  return (
    <Modal
      centered
      footer={false}
      visible={visible}
      onCancel={onCancel}
      title={
        <>
          {modalTitle()}
          &nbsp;
          {studentInfoLoading ||
          studentInfoFetching ||
          studentGroupsFetching ||
          studentGroupsLoading ? (
            <Spin />
          ) : null}
        </>
      }
      afterClose={afterCloseModal}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        className="studentsForm"
      >
        <Item
          label="F.I.O"
          name="name"
          rules={[{ required: true, message: 'Ismini kiriting!' }]}
        >
          <Input />
        </Item>
        <Item
          label="Telefon raqami"
          name="phone"
          rules={[{ required: true, message: 'Telefon raqamini kiriting!' }]}
        >
          <ReactInputMask mask="+\9\98 99 999 99 99">
            {() => <Input />}
          </ReactInputMask>
        </Item>
        <Item label="Ota-onasining telefon raqami" name="parent_number">
          <ReactInputMask mask="+\9\98 99 999 99 99">
            {() => <Input />}
          </ReactInputMask>
        </Item>
        {studentGroups?.map((group, index) => {
          return (
            <Row gutter={24} key={index}>
              <Col span={22} className="disabled_input">
                <Item label={`Guruh #${index + 1}`} name="newGroup" key={group.id}>
                  <Input defaultValue={group.name} value={group.name} disabled />
                </Item>
              </Col>
              <Col span={2} className="delete">
                <Popconfirm
                  title="O'quvchini bu guruhdan o'chirmoqchimisiz?"
                  okText="Ha"
                  cancelText="Yo'q"
                  placement="right"
                  onConfirm={() => deleteGroup(group.student_group_id)}
                >
                  <div className="delete_btn">
                    <Trash />
                  </div>
                </Popconfirm>
              </Col>
            </Row>
          )
        })}
        {!visibleNewGroup && data?.type === 'edit' ? (
          <p onClick={() => setVisibleNewGroup(true)} className="newGroup">
            Boshqa guruhga qo'shish
          </p>
        ) : null}
        {visibleNewGroup && (
          <Row gutter={24}>
            <Col span={22}>
              <Item label="Boshqa guruh">
                <Select onChange={handleNewGroup}>
                  {activeGroups?.map((group, index) => {
                    return (
                      <Select.Option key={index} value={group.id}>
                        {group.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Item>
            </Col>
            <Col span={2} className="checkIcon">
              <Button onClick={addNewGroup}>
                {connectGroupToStudent.isLoading ? <Spin /> : <CheckIcon />}
              </Button>
            </Col>
          </Row>
        )}
        <Item>
          <Button className="blue_ant_button" type="primary" htmlType="submit">
            {submitButtonText()}
          </Button>
        </Item>
      </Form>
    </Modal>
  )
}

export default StudentsModal
