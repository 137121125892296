import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserState {
  menuRes: boolean
}

const initialState: UserState = {
  menuRes: true,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    menuResponsive(state, action: PayloadAction<void>) {
      state.menuRes = !state.menuRes
    },
  },
})

export default userSlice.reducer
