import React from 'react'
import { Button, message, Modal } from 'antd'
import { ImportedQuestion } from '../../../models/test-page/Question'
import { useCreateTesFromTemplate } from '../../../queries/mutation'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../hooks/redux'

interface ShowImportedDataModalProps {
  open: boolean
  close: () => void
  data: ImportedQuestion[]
}

const ShowImportedDataModal: React.FC<ShowImportedDataModalProps> = ({
  open,
  close,
  data,
}) => {
  const { test_id } = useParams<{ test_id: string }>()

  const dispatch = useAppDispatch()

  const saveQuestionMutation = useCreateTesFromTemplate(
    test_id,
    close,
    dispatch
  )

  const saveQuestion = () => {
    if (data.length > 0) {
      saveQuestionMutation.mutate(data)
      return
    }
    message.error('savollarni import qilishda xatolik mavjud!')
  }

  return (
    <Modal
      visible={open}
      width={600}
      title="Import qilingan testlarni tasdiqlash"
      onCancel={close}
      footer={[
        <Button
          key={'back'}
          title={"Barcha import qilingan savollar o'chib ketadi!"}
          className={'border-5'}
          type={'primary'}
          danger
          onClick={close}
        >
          Ortga qaytish
        </Button>,
        <Button
          key={'success'}
          loading={saveQuestionMutation.isLoading}
          onClick={saveQuestion}
          title={'Barcha import qilingan testlar saqlanadi!'}
          type={'primary'}
          className={'border-5'}
        >
          Tasqidlash
        </Button>,
      ]}
    >
      <div className="questionsView">
        {data?.map((question, index) => {
          const setClassName = (str: string) => {
            if (str.charAt(0) === '*') {
              return 'trueAnswer'
            }
          }

          const customQuestion = (que: string) => {
            if (que.charAt(0) === '*') {
              return que.substring(1)
            } else {
              return que
            }
          }
          return (
            <div key={index}>
              <p className="que">
                {index + 1}. {question.savol}
              </p>
              <p className={setClassName(question.A)}>
                A) {customQuestion(question.A)}
              </p>
              <p className={setClassName(question.B)}>
                B) {customQuestion(question.B)}
              </p>
              <p className={setClassName(question.C)}>
                C) {customQuestion(question.C)}
              </p>
              <p className={setClassName(question.D)}>
                D) {customQuestion(question.D)}
              </p>
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

export default ShowImportedDataModal
