import React from 'react'

const TestsIcon = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 21.2014L7.86625 15.1215L9.63375 13.2951L13.75 17.5486L25.8438 5.05171C25.3975 4.34517 24.6275 3.875 23.75 3.875H6.25C4.86875 3.875 3.75 5.03104 3.75 6.45833V24.5417C3.75 25.969 4.86875 27.125 6.25 27.125H23.75C25.1313 27.125 26.25 25.969 26.25 24.5417V8.28475L13.75 21.2014Z"
        fill="#949494"
      />
    </svg>
  )
}

export default TestsIcon
