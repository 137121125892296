import { Button, Pagination, Popconfirm, Popover, Select, Table } from 'antd'
import React, { useState } from 'react'
import FileText from '../../../assets/icons/FileText'
import MoreIcon from '../../../assets/icons/MoreIcon'
import { ShablonsData } from '../../../models/block-page/Block'
import { ISelectedTestData } from '../../../models/test-page/TestData'
import { useMyBlock } from '../../../queries/queries'
import './Block.scss'

interface IProps {
  searchText: string
  setModalVisible: (visible: boolean) => void
  selectedTests: ISelectedTestData[]
  setSelectedTests: (tests: ISelectedTestData[]) => void
}

const Bloks: React.FC<IProps> = ({
  searchText,
  setModalVisible,
  selectedTests,
  setSelectedTests,
}) => {
  const { Column } = Table
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const { data, isLoading } = useMyBlock(page, pageSize, searchText)

  // pagination
  const isPagingData = () => {
    if (data?.count! > 10) {
      return true
    } else {
      return false
    }
  }

  // open modal
  const openModal = () => {
    setModalVisible(true)
  }

  // start test
  const startTest = (record: ShablonsData) => {
    const testData = {
      shablon_id: record.shablon_id,
      test_id: record.test_id,
      test_name: record.shablon_name,
      subject_name: record.subject_name,
      question_count: record.question_count,
    }
    setSelectedTests([testData])
    openModal()
  }

  return (
    <>
      <Table
        className="tableBlock"
        dataSource={data?.shablons}
        loading={isLoading}
        pagination={false}
        rowKey={(record: ShablonsData) => record.test_id}
      >
        <Column
          className="blockItem blockName"
          dataIndex={'shablon_name'}
          title="Blok nomi"
        />
        <Column
          className="blockItem"
          title="Fan"
          render={(record) =>
            record.subject_name.map((item: string, index: number) => (
              <p key={index}>{item} </p>
            ))
          }
        />
        <Column
          align="center"
          className="blockItem"
          dataIndex={'start_at'}
          title="Sana"
        />
        <Column
          align="right"
          className="blockItem"
          dataIndex={'question_count'}
          title="Savollar soni"
        />
        <Column
          className="buttonStart"
          align="right"
          render={(record) => (
            <Button
              type="primary"
              className="green_btn"
              onClick={() => startTest(record)}
            >
              <FileText />
              <span>Boshlash</span>
            </Button>
          )}
        />
        <Column
          align="center"
          className="more"
          render={(record) => (
            <Popover
              overlayClassName="more_popover"
              placement="left"
              trigger="hover click"
              mouseEnterDelay={2}
              mouseLeaveDelay={2}
              content={() => (
                <div className="more_popover_actions">
                  <p onClick={() => startTest(record)}>Tahrirlash</p>
                  <Popconfirm
                    trigger={'hover click'}
                    mouseEnterDelay={2}
                    mouseLeaveDelay={2}
                    title="Blokni o'chirmoqchimisiz?"
                    okText="Ha"
                    cancelText="Yo'q"
                    placement="left"
                    // onConfirm={() => deleteTests(record)}
                  >
                    <p>O'chirish</p>
                  </Popconfirm>
                </div>
              )}
            >
              <div className="more_icon" onClick={(e) => e.stopPropagation()}>
                <MoreIcon />
              </div>
            </Popover>
          )}
        />
      </Table>
      {isPagingData() && (
        <div className="pagination">
          <div className="pageSize">
            <p>Har bir sahifadagi qatorlar soni :</p>
            <Select defaultValue={10} onSelect={(pageSize) => setPageSize(pageSize)}>
              <Select.Option value="10">10</Select.Option>
              <Select.Option value="20">20</Select.Option>
              <Select.Option value="50">50</Select.Option>
            </Select>
          </div>
          <Pagination
            total={Number(data?.count) || 10}
            pageSize={pageSize}
            onChange={(page) => setPage(page)}
          />
        </div>
      )}
    </>
  )
}

export default Bloks
