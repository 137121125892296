import { Button } from 'antd'
import React, { FC } from 'react'
import BackIcon from '../../../assets/icons/BackIcon'
import './universal-header.scss'

interface UniversalHeaderProps {
  title: string
  button?: React.ReactNode
  backBtn?: boolean
}

const UniversalHeader: FC<UniversalHeaderProps> = ({ title, button, backBtn }) => {
  return (
    <div className="universal_header">
      {backBtn && (
        <Button onClick={() => window.history.go(-1)} className="backBtn">
          <BackIcon />
        </Button>
      )}
      <p>{title}</p>
      <div>{button}</div>
    </div>
  )
}

export default UniversalHeader
