import { Button, Form, Select } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import SelectIcon from '../../assets/icons/SelectIcon'
import { useAppSelector } from '../../hooks/redux'
import { StudentGroup } from '../../models/detailedGroup/StudentsData'
import { DirectionDataElement } from '../../models/direction/DirectionData'
import { useStartVirtualTest } from '../../queries/mutation'
import { useActiveGroups, useDirection } from '../../queries/queries'
import { DatePicker } from '../../services/DatePicker'
import './myTest/MyTest.scss'

type onFinishValues = {
  group_ids: number[]
  course_id: number
  start_at: dayjs.Dayjs
  finish_at: dayjs.Dayjs
}

const VirtualExam = () => {
  const [directionList, setDirectionList] = useState([])
  const defaultGroups = useAppSelector(
    (state) => state.selectedGroupsReducer.selectedGroups
  )
  const { data: direction } = useDirection()
  const { data: studentGroup } = useActiveGroups()
  const { Option } = Select
  const [form] = Form.useForm()
  const { Item } = Form
  const startVirtualTest = useStartVirtualTest(form)

  // subject change
  const directionChnage = (e: any) => {
    setDirectionList(e.split(' va '))
  }

  // on finish
  const onFinish = (values: onFinishValues) => {
    const data = {
      group_ids: values.group_ids,
      course_id: values.course_id,
      start_at: values.start_at.format('YYYY-MM-DD HH:mm'),
      finish_at: values.finish_at.format('YYYY-MM-DD HH:mm'),
    }
    startVirtualTest.mutate(data)
  }

  // initial values
  useEffect(() => {
    form.setFieldsValue({
      group_ids: defaultGroups,
      start_at: dayjs(),
      finish_at: dayjs().add(1, 'day'),
    })
  }, [defaultGroups, form])

  return (
    <Form form={form} onFinish={onFinish}>
      <div className="virtualExam">
        <div className="compulsory_sciences">
          <div className="virtualExam_title">
            <h1 className="virtualExam_title_h1">Majburiy fanlar majmuasi</h1>
          </div>
          <div className="virtualExam_list">
            <div>
              <p>Ona&nbsp;tili</p>
              <span></span>
              <p>10&nbsp;ta</p>
            </div>
            <div>
              <p>Matematika</p>
              <span></span>
              <p>10&nbsp;ta</p>
            </div>
            <div>
              <p>O'zbekiston&nbsp;tarixi</p>
              <span></span>
              <p>10&nbsp;ta</p>
            </div>
          </div>
          <div className="virtualExam_time">
            <h1>Qo'shimcha sozlamalar</h1>
            <div>
              <div>
                <p>Boshlanish vaqti</p>
                <Item
                  name="start_at"
                  rules={[
                    { required: true, message: 'Boshlanish vaqtini kiriting!' },
                  ]}
                >
                  <DatePicker
                    showTime={{ format: 'HH:mm' }}
                    className="virtual_input"
                    format="YYYY-MM-DD HH:mm"
                    placeholder="boshlanish vaqti"
                  />
                </Item>
              </div>
            </div>
            <div>
              <div>
                <p>Yakunlash vaqti</p>
                <Item
                  name="finish_at"
                  rules={[
                    { required: true, message: 'Yakunlash vaqtini kiriting!' },
                  ]}
                >
                  <DatePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    className="virtual_input"
                    placeholder=""
                  />
                </Item>
              </div>
            </div>
          </div>
        </div>
        <div className="elective_sciences">
          <div className="virtualExam_title">
            <h1 className="virtualExam_title_h1">Tanlov fanlar majmuasi</h1>
          </div>
          <Item
            name="course_id"
            rules={[{ required: true, message: 'Fanlar majmuasini tanlang!' }]}
          >
            <Select
              showSearch
              placeholder={'Tanlash'}
              className="custom_Select"
              style={{ width: 264, margin: '8px 0' }}
              suffixIcon={<SelectIcon />}
              onChange={(_: number, option: any) => {
                directionChnage(option.children)
              }}
            >
              {direction?.directions.map((e: DirectionDataElement) => (
                <Option key={e.id} value={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Item>
          <div className="virtualExam_list">
            {directionList.length <= 0 ? (
              <>
                <div>
                  <span></span>
                </div>
              </>
            ) : (
              <>
                {directionList.map((e: string, i: number) => (
                  <div key={i}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e.replace(' ', '&nbsp;'),
                      }}
                    />
                    <span></span>
                    <p>20&nbsp;ta</p>
                  </div>
                ))}
              </>
            )}
          </div>
          <div>
            <h1 className="virtualExam_title_h1" style={{ marginTop: 18 }}>
              Guruhni biriktirish
            </h1>
            <Item
              name="group_ids"
              rules={[{ required: true, message: 'Guruh biriktiring!' }]}
            >
              <Select
                showSearch
                mode="multiple"
                placeholder="Tanlash"
                className="custom_Select"
                style={{ width: 264, margin: '8px 0' }}
                suffixIcon={<SelectIcon />}
              >
                {studentGroup?.map((e: StudentGroup) => (
                  <Option key={e.id} value={e.id}>
                    {e.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </div>
          <Button
            loading={startVirtualTest.isLoading}
            htmlType="submit"
            type="primary"
            style={{ float: 'right' }}
          >
            Boshlash
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default VirtualExam
