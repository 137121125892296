import React, { useEffect, useState } from 'react'
import { BaseModel } from '../../../models/BaseModel'
import { Button, Checkbox, Modal } from 'antd'

interface ThemesFilterModalProps {
  data: BaseModel[]
  open: boolean
  close: (isClear: boolean) => void
  handleConfirmThemes: (data: number[]) => void
  selectedThemes: number[]
}

const ThemesFilterModal: React.FC<ThemesFilterModalProps> = ({
  open,
  close,
  data,
  handleConfirmThemes,
  selectedThemes,
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  useEffect(() => {
    setSelectedIds(selectedThemes)
  }, [selectedThemes])

  const handleSelectTheme = (checked: boolean, id: number) => {
    let newSelectedIds = selectedIds.slice()
    if (checked) {
      newSelectedIds.push(id)
    } else {
      newSelectedIds = newSelectedIds.filter((selectedId) => selectedId !== id)
    }
    setSelectedIds(newSelectedIds)
  }

  const isChecked = (id: number) => {
    return selectedIds.includes(id)
  }

  const closeModal = () => {
    close(false)
  }

  const handleConfirm = () => {
    handleConfirmThemes(selectedIds)
    close(false)
  }

  const handleClear = () => {
    close(true)
  }

  return (
    <Modal
      visible={open}
      title="Mavzular bo'yicha saralash"
      onOk={closeModal}
      width={800}
      centered
      onCancel={closeModal}
      footer={[
        <Button
          key={'delete'}
          onClick={handleClear}
          type="primary"
          danger
          className={'border-5'}
        >
          Tozalash
        </Button>,
        <Button
          key={'confirm'}
          onClick={handleConfirm}
          type="primary"
          className={'border-5'}
        >
          Tasdiqlash
        </Button>,
      ]}
    >
      <div className={'themed_filter_modal'}>
        {data.map((theme, index) => (
          <div
            className={'themed_filter_modal_item d-flex align-center'}
            key={index}
          >
            <Checkbox
              checked={isChecked(theme.id)}
              onChange={(e) => handleSelectTheme(e.target.checked, theme.id)}
              id={`check_box${index}`}
            />
            <label htmlFor={`check_box${index}`} className={'label'}>
              {theme.name}
            </label>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default ThemesFilterModal
