import { Button, Form, Input, Modal, Radio } from 'antd'
import React, { useEffect } from 'react'
import { GroupData } from '../../models/group-page/GroupData'
import { useCreateEditGroup } from '../../queries/mutation'
import { groupTypes } from '../../utils/constants/groupTypes'

interface Props {
  visible: boolean
  setVisible: (visible: boolean) => void
  data: {
    type: string
    data?: GroupData
  }
}

interface FormData {
  name: string
  description: string
}

const MyGroupModal: React.FC<Props> = ({ visible, setVisible, data }) => {
  const [form] = Form.useForm()
  const createGroupMutation = useCreateEditGroup(setVisible)

  useEffect(() => {
    if (data?.data && data.type === 'edit') {
      const groupType =
        data.data?.type === groupTypes.EXPECT ? groupTypes.PUBLIC : data.data?.type
      form.setFieldsValue({
        name: data?.data?.name,
        description: data?.data?.description,
        type: groupType,
      })
    }
  }, [data, form])

  // on cancel
  const onCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  // after close modal
  const afterCloseModal = () => {
    form.resetFields()
  }

  // on submit
  const handleSubmit = (fields: FormData) => {
    const id = data?.data?.id
    if (data?.data && data.type === 'edit') {
      createGroupMutation.mutate({ ...fields, id })
    } else {
      createGroupMutation.mutate(fields)
    }
  }

  // modal title
  const modalTitle = () => {
    if (data?.type === 'create') {
      return 'Guruh yaratish'
    } else if (data?.type === 'edit') {
      return 'Guruhni tahrirlash'
    }
  }

  // submit button text
  const submitButtonText = () => {
    if (data?.type === 'create') {
      return 'Yaratish'
    } else if (data?.type === 'edit') {
      return 'Tahrirlash'
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      centered
      title={modalTitle()}
      footer={false}
      afterClose={afterCloseModal}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="groupCreateEditForm"
      >
        <Form.Item
          label="Nomi"
          name="name"
          rules={[{ required: true, message: 'Nomini kiriting' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Ma'lumot" name="description">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Guruh turi" name="type">
          <Radio.Group defaultValue={groupTypes.PRIVATE}>
            <Radio value={groupTypes.PRIVATE}>Shaxsiy</Radio>
            <Radio value={groupTypes.PUBLIC}>Ommaviy</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button
            className="blue_ant_button"
            type="primary"
            loading={createGroupMutation.isLoading}
            htmlType="submit"
          >
            {submitButtonText()}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default MyGroupModal
