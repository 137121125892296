import {
  Button,
  message,
  Pagination,
  Popconfirm,
  Popover,
  Select,
  Table,
} from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import MyGroupHeader from '../../components/myGroup/MyGroupHeader'
import MyGroupModal from '../../components/myGroup/MyGroupModal'
import FileIcon from '../../assets/icons/FileIcon'
import MoreIcon from '../../assets/icons/MoreIcon'
import { GroupData } from '../../models/group-page/GroupData'
import { useDeleteGroup } from '../../queries/mutation'
import { useGroup } from '../../queries/queries'
import { rootPaths } from '../../routing/rootPaths'

import './MyGroup.scss'
import CancelIcon from '../../assets/icons/CancelIcon'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { selectedGroupsSlice } from '../../store/reducers/SelectedGroupsSlice'
import { groupTypes } from '../../utils/constants/groupTypes'

interface GroupModalData {
  type: string
  data?: GroupData
}

const MyGroup: React.FC = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalData, setModalData] = useState<GroupModalData>({ type: '' })
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const { Column } = Table
  const [searchText, setSearchText] = useState<string>('')
  const { data, isLoading } = useGroup(page, pageSize, searchText)
  const deleteGroupMutation = useDeleteGroup()
  // const archivateGroupMutation = useArchivateGroup()
  const { push } = useHistory()
  const { selectedGroups } = useAppSelector((state) => state.selectedGroupsReducer)
  const { setSelectedGroups } = selectedGroupsSlice.actions
  const dispatch = useAppDispatch()

  // is empty selected groups
  const isEmptySelectedGroups = () => {
    return selectedGroups.length <= 0
  }

  // close modal
  const closeModal = (e?: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    if (e) {
      e.stopPropagation()
    }
    setModalVisible(false)
  }

  // edit button
  const editGroup = (
    record: GroupData,
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => {
    e.stopPropagation()
    setModalVisible(true)
    setModalData({ ...modalData, type: 'edit', data: record })
  }

  // delete button
  const deleteGroup = (
    record: GroupData,
    e: React.MouseEvent<HTMLElement, MouseEvent> | undefined
  ) => {
    e?.stopPropagation()
    deleteGroupMutation.mutate(record.id)
  }

  // archive button
  // const archivateGroup = (
  //   record: GroupData,
  //   e: React.MouseEvent<HTMLElement, MouseEvent> | undefined
  // ) => {
  //   e?.stopPropagation()
  //   archivateGroupMutation.mutate(record.id)
  // }

  // row selection
  const onRowSelect = (record: GroupData, selected: boolean) => {
    if (selected) {
      if (!selectedGroups.includes(record.id)) {
        const newGroups = [...selectedGroups, record.id]
        dispatch(setSelectedGroups(newGroups))
      }
    } else {
      let newGroups = selectedGroups.slice()
      for (let i = 0; i < newGroups.length; i++) {
        if (newGroups[i] === record.id) {
          newGroups.splice(i, 1)
          dispatch(setSelectedGroups(newGroups))
        }
      }
    }
  }
  const onRowSelectAll = (selected: boolean, _: any, changeRows: GroupData[]) => {
    if (selected) {
      let newGroups: number[] = selectedGroups.slice()
      for (let i = 0; i < changeRows.length; i++) {
        if (!selectedGroups.includes(changeRows[i].id)) {
          newGroups = [...newGroups, changeRows[i].id]
        }
        dispatch(setSelectedGroups(newGroups))
      }
    } else {
      let newGroups: number[] = selectedGroups.slice()
      for (let i = 0; i < changeRows.length; i++) {
        if (newGroups.includes(changeRows[i].id)) {
          newGroups = newGroups.filter((id) => {
            return id !== changeRows[i].id
          })
        }
        dispatch(setSelectedGroups(newGroups))
      }
    }
  }
  const rowSelection = {
    selectedRowKeys: selectedGroups,
    onSelect: onRowSelect,
    onSelectAll: onRowSelectAll,
    onClick: (e: any) => e.stopPropagation(),
  }

  // clear selected row keys
  const clearSelectedRowKeys = () => {
    dispatch(setSelectedGroups([]))
  }

  // on row click
  const onRowClick = (record: GroupData) => {
    if (isEmptySelectedGroups()) {
      push(`${rootPaths.myGroup}/${record.id}`)
    } else {
      if (!selectedGroups.includes(record.id)) {
        const newGroups = [...selectedGroups, record.id]
        dispatch(setSelectedGroups(newGroups))
      } else {
        let newGroups = selectedGroups.slice()
        for (let i = 0; i < newGroups.length; i++) {
          if (newGroups[i] === record.id) {
            newGroups.splice(i, 1)
            dispatch(setSelectedGroups(newGroups))
          }
        }
      }
    }
  }

  // pagination
  const isPagingData = () => {
    if (Number(data?.count) > 10) {
      return true
    } else {
      return false
    }
  }

  // start test
  const startTestOneGroup = (
    record: GroupData,
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    dispatch(setSelectedGroups([record.id]))
    e.stopPropagation()
    push(`${rootPaths.startTest + rootPaths.myTest}`)
    message.success('Guruh tanlandi, testni boshlashingiz mumkin!')
  }

  // start test with selected groups
  const startTestWithSelectedGroups = () => {
    push(`${rootPaths.startTest + rootPaths.myTest}`)
    message.success('Guruhlar tanlandi, testni boshlashingiz mumkin!')
  }

  // group type
  const groupType = (type: string) => {
    switch (type) {
      case groupTypes.EXPECT:
        return 'Tasdiq kutilmoqda'
      case groupTypes.PRIVATE:
        return 'Shaxsiy'
      case groupTypes.PUBLIC:
        return 'Umumiy'
      default:
        return ''
    }
  }

  return (
    <div className="component_container myGroup">
      <MyGroupHeader
        setModalVisible={setModalVisible}
        setModalData={setModalData}
        setSearchText={setSearchText}
        searchText={searchText}
      />
      {!isEmptySelectedGroups() && (
        <div className="extraRow">
          <p className="count">Tanlangan guruhlar: {selectedGroups.length}</p>
          <div className="moreActions">
            <Button
              type="primary"
              className="white_btn"
              onClick={startTestWithSelectedGroups}
            >
              <FileIcon />
              <span>Test o'tkazish</span>
            </Button>
            <div className="cancelBtn" onClick={clearSelectedRowKeys}>
              <CancelIcon />
            </div>
          </div>
        </div>
      )}
      <Table
        className="myGroup_table"
        dataSource={data?.groups}
        loading={isLoading}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            onRowClick(record)
          },
        })}
        rowKey={(record) => record.id}
        rowSelection={rowSelection}
      >
        <Column align="left" title="Nomi" dataIndex="name" />
        <Column align="center" title="Tashkil etilgan sana" dataIndex="created_at" />
        <Column
          align="center"
          title="Guruh turi"
          render={(record) => groupType(record.type)}
        />
        <Column align="right" title="O'quvchilar soni" dataIndex="student_count" />
        <Column
          align="center"
          title={`${isEmptySelectedGroups() ? 'Test boshlash' : ''}`}
          className="start_test"
          render={(record) => {
            if (isEmptySelectedGroups()) {
              return (
                <Button
                  type="primary"
                  className="green_btn"
                  onClick={(e) => startTestOneGroup(record, e)}
                >
                  <FileIcon />
                  <span>Test o'tkazish</span>
                </Button>
              )
            }
          }}
        />
        <Column
          align="center"
          className="more"
          render={(record) => (
            <Popover
              overlayClassName="more_popover"
              placement="left"
              trigger="hover click"
              mouseEnterDelay={2}
              mouseLeaveDelay={2}
              content={() => (
                <div className="more_popover_actions">
                  <p onClick={(e) => editGroup(record, e)}>Tahrirlash</p>
                  <Popconfirm
                    trigger={'hover click'}
                    mouseEnterDelay={2}
                    mouseLeaveDelay={2}
                    title="Guruhni o'chirmoqchimisiz?"
                    onConfirm={(e) => deleteGroup(record, e)}
                    okText="Ha"
                    cancelText="Yo'q"
                    placement="left"
                  >
                    <p onClick={(e) => closeModal(e)}>O'chirish</p>
                  </Popconfirm>
                  {/* <Popconfirm
                    trigger={'hover click'}
                    mouseEnterDelay={2}
                    mouseLeaveDelay={2}
                    title="Guruhni arxivlamoqchimisiz?"
                    onConfirm={(e) => archivateGroup(record, e)}
                    okText="Ha"
                    cancelText="Yo'q"
                    placement="left"
                  >
                    <p onClick={(e) => closeModal(e)}>Arxivlash</p>
                  </Popconfirm> */}
                </div>
              )}
            >
              <div className="more_icon" onClick={(e) => e.stopPropagation()}>
                <MoreIcon />
              </div>
            </Popover>
          )}
        />
      </Table>
      {isPagingData() && (
        <div className="pagination">
          <div className="pageSize">
            <p>Har bir sahifadagi qatorlar soni :</p>
            <Select defaultValue={10} onSelect={(pageSize) => setPageSize(pageSize)}>
              <Select.Option value="10">10</Select.Option>
              <Select.Option value="20">20</Select.Option>
              <Select.Option value="50">50</Select.Option>
            </Select>
          </div>
          <Pagination
            total={Number(data?.count) || 10}
            pageSize={pageSize}
            onChange={(page) => setPage(page)}
          />
        </div>
      )}
      <MyGroupModal
        visible={modalVisible}
        setVisible={setModalVisible}
        data={modalData}
      />
    </div>
  )
}

export default MyGroup
