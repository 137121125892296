import React, { useEffect } from 'react'
import { Button, Form, Input, message, Select } from 'antd'
import { useMyPage, useSubjcet } from '../../../queries/queries'
import SelectIcon from '../../../assets/icons/SelectIcon'
import { useCreateAndEditTest } from '../../../queries/mutation'
import { useHistory } from 'react-router-dom'

interface EditTestInfoProps {
  id: string
  data?: {
    lesson: string | undefined
    subject: string | undefined
  }
}

interface FormInterface {
  lesson: string
  subject: string
}

const CreateTestInfo: React.FC<EditTestInfoProps> = ({ id, data }) => {
  const [form] = Form.useForm<FormInterface>()
  const { push } = useHistory()

  const { data: subjects, isLoading: subjectsLoading } = useSubjcet()
  const { data: user } = useMyPage()

  const createTest = useCreateAndEditTest(push)

  useEffect(() => {
    if (user?.subject_name && id === 'new') {
      form.setFieldsValue({
        subject: user.subject_name,
      })
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (data?.subject) {
      form.setFieldsValue({
        subject: data.subject,
        lesson: data.lesson,
      })
    }
    // eslint-disable-next-line
  }, [data])

  const handleFinish = (val: FormInterface) => {
    const subject = subjects?.find((item) => item.name === val.subject)
    if (subject?.id) {
      createTest.mutate({
        subject: subject.id,
        lesson: val.lesson,
      })
    } else {
      message.error('Fan topilmadi!')
    }
  }

  return (
    <Form form={form} name="control-hooks" onFinish={handleFinish}>
      <div className={'testInfoCont d-flex align-center'}>
        <div className={'testInfoItemCont'}>
          <label htmlFor={'subject'}>Fan tanlash</label>
          <Form.Item
            name="subject"
            rules={[{ required: true, message: 'Fan tanlanmagan' }]}
          >
            <Select
              disabled={id !== 'new'}
              id={'subject'}
              placeholder={'Tanlash'}
              suffixIcon={<SelectIcon />}
              allowClear
              showSearch
              loading={subjectsLoading}
              className={'select'}
            >
              {subjects?.map((subject) => (
                <Select.Option key={subject.id} value={subject.name}>
                  {subject.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className={'testInfoItemCont'}>
          <label htmlFor={'lesson'}>Testga nom berish</label>
          <Form.Item
            name="lesson"
            rules={[{ required: true, message: 'Test nomi kiritilmagan!' }]}
          >
            <Input
              disabled={id !== 'new'}
              id={'lesson'}
              placeholder={'Nomi...'}
              className={'input'}
            />
          </Form.Item>
        </div>
        <Form.Item style={{ paddingTop: '1.2rem' }}>
          <Button
            disabled={id !== 'new'}
            loading={createTest.isLoading}
            htmlType={'submit'}
            type={'primary'}
            className={'border-5'}
          >
            Saqlash
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export default CreateTestInfo
