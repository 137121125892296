import 'antd/dist/antd.variable.min.css'
import React from 'react'
import Header from './components/header/Header'
import Navbar from './components/navbar/Navbar'
import RootRouter from './routing/RootRouter'
import './styles/App.scss'

const App = () => {
  return (
    <div>
      <Header />
      <div className={'flex'}>
        <Navbar />
        <RootRouter />
      </div>
    </div>
  )
}

export default App
