export const queryNames = {
  IS_AUTH: 'IS_AUTH',
  TEACHER_GROUP: 'TEACHER_GROUP',
  ONE_GROUP: 'ONE_GROUP',
  ACTIVE_GROUPS: 'ACTIVE_GROUPS',
  STUDENTS: 'STUDENTS',
  ONE_STUDENT_INFO: 'ONE_STUDENT_INFO',
  ONE_STUDENT_GROUPS: 'ONE_STUDENT_GROUPS',
  ATTACHED_TESTS: 'ATTACHED_TESTS',
  TESTING_STUDENTS: 'TESTING_STUDENTS',
  MY_TEST: 'MY_TEST',
  MY_PAGE: 'MY-PAGE',
  ADDRESSREGION: 'ADDRESSREGION',
  ADDRESSDISTRICT: 'ADDRESSDISTRICT',
  SUBJECT_MY_PAGE: 'SUBJECT_MY_PAGE',
  DIRECTION: 'DIRECTION',
  STUDENT_GROUP: 'STUDENT_GROUP',
  SUBJECT_TEST: 'SUBJECT_TEST',
  SUBJECT: 'SUBJECT',
  ONE_TEST: 'ONE_TEST',
  YEARS: 'YEARS',
  AIM_TEST: 'AIM_TEST',
  SUBJECTS_HAS_TEST: 'SUBJECTS_HAS_TEST',
  SUBJECTS_THEME: 'SUBJECTS_THEME',
  BLOCK: 'BLOCK',
}
