import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/UserSlice'
import selectedGroupsReducer from './reducers/SelectedGroupsSlice'
import questionListSlice from './reducers/questionListSlice'

const rootReducer = combineReducers({
  userReducer,
  selectedGroupsReducer,
  questionListSlice,
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
