import React, { useState } from 'react'
import './MyTest.scss'
import { Table, Pagination, Button, Popover, Popconfirm, Select } from 'antd'
import { useMyTests } from '../../../queries/queries'
import FileText from '../../../assets/icons/FileText'
import { ISelectedTestData, TestData } from '../../../models/test-page/TestData'
import { useDeleteTests } from '../../../queries/mutation'
import { rootPaths } from '../../../routing/rootPaths'
import { useHistory } from 'react-router-dom'
import FileIcon from '../../../assets/icons/FileIcon'
import CancelIcon from '../../../assets/icons/CancelIcon'
import MoreIcon from '../../../assets/icons/MoreIcon'

interface IProps {
  searchText: string
  setModalVisible: (visible: boolean) => void
  selectedTests: ISelectedTestData[]
  setSelectedTests: (tests: ISelectedTestData[]) => void
}

const MyTests: React.FC<IProps> = ({
  searchText,
  selectedTests,
  setSelectedTests,
  setModalVisible,
}) => {
  const { Column } = Table
  const { push } = useHistory()
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const { data, isLoading } = useMyTests(page, pageSize, searchText)
  const deleteTestsMutation = useDeleteTests()

  //delete test
  const deleteTests = (record: TestData) => {
    deleteTestsMutation.mutate(record.test_id)
  }

  // edit test
  const editTest = (id: string) => {
    push(`${rootPaths.startTest + rootPaths.myTest}/${id}`)
  }

  // on row select
  const onRowSelect = (record: TestData, selected: boolean) => {
    let tests = selectedTests.slice()

    if (selected) {
      tests.push(record)
    } else {
      tests = tests.filter((test) => test.test_id !== record.test_id)
    }
    setSelectedTests(tests)
  }
  const returnSelectedRowKeys: () => number[] = () => {
    const ids = []
    for (let i = 0; i < selectedTests.length; i++) {
      ids.push(selectedTests[i].test_id)
    }
    return ids
  }

  // on row select all
  const onSelectAll = (
    selected: boolean,
    selectedRows: TestData[],
    changeRows: TestData[]
  ) => {
    let tests = selectedTests.slice()
    if (selected) {
      for (let i = 0; i < changeRows.length; i++) {
        if (!tests.includes(changeRows[i])) {
          tests.push(changeRows[i])
        }
      }
    } else {
      for (let i = 0; i < changeRows.length; i++) {
        tests = tests.filter((test) => test.test_id !== changeRows[i].test_id)
      }
    }
    setSelectedTests(tests)
  }

  // row selection
  const rowSelection = {
    selectedRowKeys: returnSelectedRowKeys(),
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
  }

  // is empty selected tests
  const isEmptySelectedTests = () => {
    return selectedTests.length <= 0
  }

  // clear selected tests
  const clearSelectedTests = () => {
    setSelectedTests([])
  }

  const openModal = () => {
    setModalVisible(true)
  }

  // pagination
  const isPagingData = () => {
    if (data?.count! > 10) {
      return true
    } else {
      return false
    }
  }

  // start test
  const startTest = (record: TestData) => {
    const testData = {
      test_id: record.test_id,
      test_name: record.test_name,
      subject_name: record.subject_name,
      question_count: record.question_count,
    }
    setSelectedTests([testData])
    openModal()
  }

  return (
    <div className="myTests">
      {/* <div className="filtr">
        <Filtr />
        <p>Filtr</p>
      </div> */}
      {!isEmptySelectedTests() && (
        <div className="extraRow">
          <p className="count">Tanlangan guruhlar: {selectedTests.length} </p>
          <div className="moreActions">
            <Button onClick={openModal} type="primary" className="white_btn">
              <FileIcon />
              <span>Test o'tkazish</span>
            </Button>
            <div className="cancelBtn" onClick={clearSelectedTests}>
              <CancelIcon />
            </div>
          </div>
        </div>
      )}
      <Table
        loading={isLoading}
        dataSource={data?.tests}
        pagination={false}
        className="tableMyTest"
        rowKey={(record: TestData) => record.test_id}
        rowSelection={rowSelection}
      >
        <Column align="left" title="Test nomi" dataIndex={'test_name'} />
        <Column align="left" title="Fan" dataIndex={'subject_name'} />
        <Column align="center" title="Sana" dataIndex={'date'} />
        <Column align="right" title="Savollar soni" dataIndex={'question_count'} />
        {isEmptySelectedTests() && (
          <Column
            align="center"
            render={(record) => (
              <Button
                className="green_btn"
                type="primary"
                onClick={() => startTest(record)}
              >
                <FileText />
                <span>Boshlash</span>
              </Button>
            )}
          />
        )}
        <Column
          align="center"
          title=""
          render={(record) => (
            <Popover
              overlayClassName="more_popover"
              placement="left"
              trigger="click"
              content={() => (
                <div className="more_popover_actions">
                  <p onClick={() => editTest(record.test_id)}>Tahrirlash</p>
                  <Popconfirm
                    trigger={'click'}
                    title="Testni o'chirmoqchimisiz?"
                    okText="Ha"
                    cancelText="Yo'q"
                    placement="left"
                    onConfirm={() => deleteTests(record)}
                  >
                    <p>O'chirish</p>
                  </Popconfirm>
                </div>
              )}
            >
              <div className="more_icon" onClick={(e) => e.stopPropagation()}>
                <MoreIcon />
              </div>
            </Popover>
          )}
        />
      </Table>
      {isPagingData() && (
        <div className="pagination">
          <div className="pageSize">
            <p>Har bir sahifadagi qatorlar soni :</p>
            <Select defaultValue={10} onSelect={(pageSize) => setPageSize(pageSize)}>
              <Select.Option value="10">10</Select.Option>
              <Select.Option value="20">20</Select.Option>
              <Select.Option value="50">50</Select.Option>
            </Select>
          </div>
          <Pagination
            total={Number(data?.count) || 10}
            pageSize={pageSize}
            onChange={(page) => setPage(page)}
          />
        </div>
      )}
    </div>
  )
}
export default MyTests
