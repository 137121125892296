import React, { useState } from 'react'
import './Tests.scss'
import TestsHeader from '../../components/tests/TestsHeader'
import { Form, Tabs } from 'antd'
import MyTests from '../../components/tests/myTest/MyTests'
import { useHistory, useLocation } from 'react-router-dom'
import Bloks from '../../components/tests/bloks/Bloks'
import VirtualExam from '../../components/tests/VirtualExam'
import DTMtest from '../../components/tests/DTMtest'
import { rootPaths } from '../../routing/rootPaths'
import AimTests from '../../components/tests/aimTests/AimTests'
import { ISelectedTestData } from '../../models/test-page/TestData'
import StartTestModal from '../../components/tests/startTestModal/StartTestModal'

const Tests = () => {
  const location = useLocation()
  const tabDefaultKey = location.pathname.replace(`${rootPaths.startTest}`, '')
  const { TabPane } = Tabs
  const history = useHistory()
  const [searchForm] = Form.useForm()
  const [searchText, setSearchText] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedTests, setSelectedTests] = useState<ISelectedTestData[]>([])

  const callback = (key: string) => {
    setSearchText('')
    searchForm.resetFields()
    history.push(`${rootPaths.startTest}${key}`)
  }

  const showCreateButton: () => boolean = () => {
    return tabDefaultKey === rootPaths.myTest || tabDefaultKey === ''
  }

  const showSearch: () => boolean = () => {
    if (
      tabDefaultKey === rootPaths.virtualExam ||
      tabDefaultKey === rootPaths.dtmTest
    ) {
      return false
    } else {
      return true
    }
  }

  return (
    <div className="component_container">
      <TestsHeader
        showCreateButton={showCreateButton()}
        showSearch={showSearch()}
        searchText={searchText}
        setSearchText={setSearchText}
        form={searchForm}
      />
      <Tabs
        defaultActiveKey={tabDefaultKey}
        className="tabsTests"
        onChange={callback}
      >
        <TabPane tab="Mening testlarim" key={rootPaths.myTest}>
          <MyTests
            searchText={searchText}
            setModalVisible={setModalVisible}
            selectedTests={selectedTests}
            setSelectedTests={setSelectedTests}
          />
        </TabPane>
        <TabPane tab="Aim testlari" key={rootPaths.aimTests}>
          <AimTests
            searchText={searchText}
            setModalVisible={setModalVisible}
            selectedTests={selectedTests}
            setSelectedTests={setSelectedTests}
          />
        </TabPane>
        <TabPane tab="Bloklar" key={rootPaths.blocks}>
          <Bloks
            searchText={searchText}
            setModalVisible={setModalVisible}
            selectedTests={selectedTests}
            setSelectedTests={setSelectedTests}
          />
        </TabPane>
        <TabPane tab="Virtual imtihon" key={rootPaths.virtualExam}>
          <VirtualExam />
        </TabPane>
        <TabPane tab="DTM test" key={rootPaths.dtmTest}>
          <DTMtest />
        </TabPane>
      </Tabs>
      <StartTestModal
        setModalVisible={setModalVisible}
        selectedTests={modalVisible ? selectedTests : []}
        modalVisible={modalVisible}
        setSelectedTests={setSelectedTests}
      />
    </div>
  )
}
export default Tests
