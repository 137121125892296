import React from 'react'

const TelegramIcon: React.FC = () => {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4576 0.170482L0.624451 6.29043C-0.0529367 6.57541 -0.282053 7.14617 0.460697 7.4559L4.77915 8.74976L15.2205 2.66591C15.7906 2.28396 16.3743 2.38582 15.8721 2.80595L6.90432 10.4612L6.62261 13.7009C6.88354 14.2011 7.36129 14.2034 7.66602 13.9548L10.1471 11.7414L14.3963 14.7413C15.3832 15.2922 15.9202 14.9367 16.1325 13.9271L18.9197 1.48471C19.209 0.241906 18.7155 -0.305672 17.4576 0.170482Z"
        fill="#0C4192"
      />
    </svg>
  )
}

export default TelegramIcon
