export default function SelectIcon() {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.120954 0.727679L5.72764 6.33437L11.3342 0.727783L0.120954 0.727679Z"
        fill="#0C4292"
      />
    </svg>
  );
}
