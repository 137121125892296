import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Question } from '../../models/test-page/Question'

const emptyQuestion: Question = {
  question: {
    name: '',
    id: 0,
  },
  answers: {
    a: {
      name: '',
      is_correct: 1,
    },
    b: {
      name: '',
      is_correct: 0,
    },
    c: {
      name: '',
      is_correct: 0,
    },
    d: {
      name: '',
      is_correct: 0,
    },
  },
}

interface IInitialState {
  questionsList: Question[]
  isNewItemHas: boolean
}

const initialState: IInitialState = {
  questionsList: [],
  isNewItemHas: false,
}
export const questionListSlice = createSlice({
  name: 'selectedGroups',
  initialState,
  reducers: {
    setQuestionList(state, action: PayloadAction<Question[]>) {
      state.questionsList = action.payload
    },
    removeQuestionFromList(
      state,
      action: PayloadAction<{ id: number; isNewItems?: boolean }>
    ) {
      let list = [...state.questionsList]
      list = list.filter(
        (question) => question.question.id !== action.payload.id
      )
      state.questionsList = list
      if (action.payload.isNewItems !== undefined)
        state.isNewItemHas = action.payload.isNewItems
    },
    addNewQuestion(state) {
      const list = [...state.questionsList]
      list.push(emptyQuestion)
      state.questionsList = list
      state.isNewItemHas = true
    },
    updateQuestion(
      state,
      action: PayloadAction<{ data: Question; id: number }>
    ) {
      const list = [...state.questionsList]
      const findIndex = list.findIndex(
        (question) => question.question.id === action.payload.id
      )
      list[findIndex] = action.payload.data
      state.questionsList = list
      if (action.payload.id === 0) state.isNewItemHas = false
    },
    andQuestions(state, action: PayloadAction<Question[]>) {
      let list = [...state.questionsList]
      list = action.payload.concat(list)
      state.questionsList = list
    },
    backToInitialState(state) {
      state.questionsList = initialState.questionsList
      state.isNewItemHas = initialState.isNewItemHas
    },
  },
})
export const {
  setQuestionList,
  removeQuestionFromList,
  addNewQuestion,
  updateQuestion,
  andQuestions,
  backToInitialState,
} = questionListSlice.actions
export default questionListSlice.reducer
