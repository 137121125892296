import React from 'react'

const LogoutIcon: React.FC = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0815 11.6922L27.3315 16.9422L22.0815 22.1922"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3315 16.9422H27.3315"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3315 27.9422H6.33154C6.06633 27.9422 5.81197 27.8369 5.62444 27.6493C5.4369 27.4618 5.33154 27.2074 5.33154 26.9422V6.94223C5.33154 6.67701 5.4369 6.42266 5.62444 6.23512C5.81197 6.04759 6.06633 5.94223 6.33154 5.94223H13.3315"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LogoutIcon
