import React from 'react'

const Import = () => {
  return (
    <svg
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.375 0.633789H2.625C1.6625 0.633789 0.875 1.42129 0.875 2.38379V5.87504H2.625V2.36629H18.375V14.6425H2.625V11.125H0.875V14.6338C0.875 15.5963 1.6625 16.3663 2.625 16.3663H18.375C19.3375 16.3663 20.125 15.5963 20.125 14.6338V2.38379C20.125 1.41254 19.3375 0.633789 18.375 0.633789ZM9.625 12L13.125 8.50004L9.625 5.00004V7.62504H0.875V9.37504H9.625V12ZM18.375 0.633789H2.625C1.6625 0.633789 0.875 1.42129 0.875 2.38379V5.87504H2.625V2.36629H18.375V14.6425H2.625V11.125H0.875V14.6338C0.875 15.5963 1.6625 16.3663 2.625 16.3663H18.375C19.3375 16.3663 20.125 15.5963 20.125 14.6338V2.38379C20.125 1.41254 19.3375 0.633789 18.375 0.633789ZM9.625 12L13.125 8.50004L9.625 5.00004V7.62504H0.875V9.37504H9.625V12Z"
        fill="#0C4292"
      />
    </svg>
  )
}

export default Import
