import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { rootRoutes } from './rootRoutes'

const RootRouter = () => {
  return (
    <Switch>
      {rootRoutes.map((route) => (
        <Route
          path={route.path}
          exact={route.exact}
          key={route.path}
          component={route.component}
        />
      ))}
    </Switch>
  )
}

export default RootRouter
