import { Redirect, useLocation } from 'react-router-dom'
import { mainPaths, rootPaths } from '../routing/rootPaths'
import { AuthService } from './AuthService'

const CheckLogIn = (props: any) => {
  const { pathname } = useLocation()

  // is pathname login
  const isPathnameLogin = () => {
    if (pathname === mainPaths.login) {
      return true
    } else return false
  }

  if (AuthService.checkLogin()) {
    if (!isPathnameLogin()) {
      return props.children
    } else {
      return <Redirect to={rootPaths.myGroup} />
    }
  } else {
    if (isPathnameLogin()) {
      return props.children
    } else {
      return <Redirect to={mainPaths.login} />
    }
  }
}

export default CheckLogIn
