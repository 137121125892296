import { MathJaxContext } from 'better-react-mathjax'

const MathjaxContext: (any: any) => any = ({ children }) => {
  return (
    // @ts-ignore
    <MathJaxContext
      config={{
        asciimath2jax: {
          useMathMLspacing: true,
          delimiters: [['$$', '$$']],
          inlineMath: [['$', '$']],
          preview: 'none',
        },
        tex2jax: {
          inlineMath: [['@', '@']],
          displayMath: [['@@', '@@']],
          processEscapes: true,
          processEnvironments: true,
          preview: 'none',
        },
      }}
      version={2}
    >
      {children}
    </MathJaxContext>
  )
}

export default MathjaxContext
