import { Button, Col, Form, Input, Modal, Row, Select } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { BlockFormFields } from '../../../models/block-page/Block'
import { ISelectedTestData } from '../../../models/test-page/TestData'
import { useStartTest } from '../../../queries/mutation'
import { useActiveGroups } from '../../../queries/queries'
import { DatePicker } from '../../../services/DatePicker'
import { selectedGroupsSlice } from '../../../store/reducers/SelectedGroupsSlice'

import './StartTestModal.scss'

const { Item } = Form

interface Props {
  modalVisible: boolean
  setModalVisible: (modalVisible: boolean) => void
  selectedTests: ISelectedTestData[]
  setSelectedTests: (tests: ISelectedTestData[]) => void
}

const StartTestModal: React.FC<Props> = ({
  modalVisible,
  selectedTests,
  setModalVisible,
  setSelectedTests,
}) => {
  const [form] = Form.useForm()
  const { data: dataGroup } = useActiveGroups()
  const defaultGroups = useAppSelector(
    (state) => state.selectedGroupsReducer.selectedGroups
  )
  const dispatch = useAppDispatch()
  const { setSelectedGroups } = selectedGroupsSlice.actions
  const { Option } = Select
  const selectedGroups = form.getFieldValue('group_ids')
  const [isThereGroups, setIsThereGroups] = useState<boolean>(
    selectedGroups?.length > 0
  )
  const useCreateBlockMutation = useStartTest(setModalVisible, isThereGroups)

  // questions count
  const questionsCount = useCallback(() => {
    let numberTest = 0
    if (selectedTests) {
      for (let i = 0; i < selectedTests.length; i++) {
        numberTest += selectedTests[i].question_count
      }
    }
    return numberTest
  }, [selectedTests])

  // all subjects
  const allSubjects = () => {
    if (selectedTests) {
      let newSubjects: string[] = []
      selectedTests.forEach((item: ISelectedTestData) => {
        if (typeof item.subject_name === 'string') {
          if (!newSubjects.includes(item.subject_name)) {
            newSubjects = [...newSubjects, item.subject_name]
          }
        } else if (Array.isArray(item.subject_name)) {
          newSubjects = item.subject_name
        }
      })
      return newSubjects
    }
  }

  // tamplate name
  const templateName = useCallback(() => {
    if (selectedTests) {
      let nameString = ''
      for (let i = 0; i < selectedTests.length; i++) {
        if (nameString === '') {
          nameString += `${selectedTests[i].test_name}`
        } else {
          nameString += `, ${selectedTests[i].test_name}`
        }
      }
      return nameString
    }
  }, [selectedTests])

  // for initial values
  useEffect(() => {
    // template name
    if (templateName()?.length! > 0) {
      form.setFieldsValue({
        name: templateName(),
      })
    }

    // questions count
    if (questionsCount() > 0) {
      form.setFieldsValue({
        question_count: questionsCount(),
        time_limit: questionsCount() * 3,
      })
    } else {
      form.setFieldsValue({
        question_count: '',
        time_limit: '',
      })
    }

    // is there groups
    if (defaultGroups?.length > 0) {
      setIsThereGroups(true)
    } else {
      setIsThereGroups(false)
    }

    // date
    form.setFieldsValue({
      date: [dayjs(), dayjs().add(1, 'day')],
      group_ids: defaultGroups,
    })
  }, [questionsCount, templateName, form, defaultGroups])

  // on cancel
  const handleCancel = () => {
    setModalVisible(false)
  }

  // after close modal
  const afterCloseModal = () => {
    setSelectedTests([])
    dispatch(setSelectedGroups([]))
    form.resetFields()
    setIsThereGroups(false)
  }

  // on finish
  const onFinish = (fields: BlockFormFields) => {
    let test_ids: number[] = []
    if (selectedTests) {
      for (let i = 0; i < selectedTests.length; i++) {
        test_ids = [...test_ids, selectedTests[i].test_id!]
      }
    }

    let shablon_id
    if (setSelectedTests.length === 1 && selectedTests[0].shablon_id) {
      shablon_id = selectedTests[0].shablon_id
    }

    const dataUpdateBlock = {
      shablon_name: fields.name,
      group_ids: fields.group_ids,
      shablon_id,
      test_ids,
      question_count: fields.question_count,
      start_at: dayjs(fields.date[0]).format('YYYY-MM-DD HH:mm:ss'),
      finish_at: dayjs(fields.date[1]).format('YYYY-MM-DD HH:mm:ss'),
      time_limit: fields.time_limit,
    }

    useCreateBlockMutation.mutate(dataUpdateBlock)
  }

  // questions count
  const changeCountQuestions = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      time_limit: Number(e.target.value) * 3,
    })
  }

  // is empty selected groups
  const changeSelectedTests = (ids: number[]) => {
    if (ids?.length > 0) {
      setIsThereGroups(true)
    } else {
      setIsThereGroups(false)
    }
  }

  // modal title
  const modalTitle = () => {
    if (isThereGroups) {
      return 'Shablon yaratish va test boshlash parametrlari'
    } else {
      return 'Shablon yaratish parametrlari'
    }
  }

  // submit button text
  const submitButtonText = () => {
    if (isThereGroups) {
      return 'Saqlash va boshlash'
    } else {
      return 'Saqlash'
    }
  }

  return (
    <Modal
      centered
      width={757}
      onCancel={handleCancel}
      visible={modalVisible}
      className="startTestModal"
      title={modalTitle()}
      afterClose={afterCloseModal}
      footer={[
        <div className="footer">
          <p className="count">
            Umumiy savollar soni: <span>{questionsCount()}</span>
          </p>
          <Button
            loading={useCreateBlockMutation.isLoading}
            onClick={() => form.submit()}
            className="blue_ant_button"
            type="primary"
            htmlType="submit"
          >
            {submitButtonText()}
          </Button>
        </div>,
      ]}
    >
      <Form
        className="startTestForm"
        layout="vertical"
        onFinish={onFinish}
        form={form}
      >
        <p className="label">Nomi</p>
        <Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Shablon nomni kiriting!',
            },
          ]}
        >
          <Input />
        </Item>
        <div className="subjects">
          <p className="label">Fanlar</p>
          <p className="subjects_name">
            {allSubjects()?.map((item: string, index: number) => {
              return (
                <span key={index}>
                  {index > 0 && ' | '}
                  {item}
                </span>
              )
            })}
          </p>
        </div>
        <Row gutter={24}>
          <Col span={16}>
            <p className="label">Boshlanish va yakunlanish vaqti</p>
            <Item
              name="date"
              rules={[
                {
                  required: isThereGroups,
                  message: isThereGroups ? 'Boshlanish vaqtini kiriting!' : '',
                },
              ]}
            >
              <DatePicker.RangePicker
                showTime={{ format: 'HH:mm' }}
                allowEmpty={[false, false]}
                allowClear={true}
                format="DD.MM.YYYY HH:mm"
                placeholder={['Boshlanish vaqti', 'Yakunlanish vaqti']}
                disabledDate={(currentDate: dayjs.Dayjs) => {
                  return currentDate < dayjs()
                }}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <p className="label">Natijaviy savollar miqdor</p>
            <Item
              name="question_count"
              rules={[
                {
                  required: true,
                  message: 'Savollar miqdorini kiriting!',
                },
                () => ({
                  validator(_, value) {
                    if (value > questionsCount()) {
                      return Promise.reject(
                        new Error(`Umumiy savollar soni ${questionsCount()} ta`)
                      )
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <Input
                onChange={(e) => changeCountQuestions(e)}
                className="mini_input"
                type="number"
              />
            </Item>
          </Col>
          <Col span={8}>
            <p className="label">Davomiyligi (daq)</p>
            <Item
              name="time_limit"
              rules={[
                {
                  required: isThereGroups,
                  message: 'Davomiylik vaqtini kiriting!',
                },
              ]}
            >
              <Input className="mini_input" type="number" />
            </Item>
          </Col>
        </Row>
        <div className="groups">
          <p className="label_lg">Guruhga biriktirish</p>
          <p className="label_sm">
            Ushbu testni bir yoki bir nechta guruhga biriktirishingiz mumkin
          </p>
          <Item name="group_ids">
            <Select
              mode="multiple"
              allowClear
              placeholder="Guruhni tanlang"
              onChange={changeSelectedTests}
            >
              {dataGroup?.map((item: { id: number; name: string }) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Item>
        </div>
      </Form>
    </Modal>
  )
}

export default StartTestModal
