import React from 'react'

const ShareIcon: React.FC = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4167 4.62502V0.791687L18.125 7.50002L11.4167 14.2084V10.2792C6.625 10.2792 3.27083 11.8125 0.875 15.1667C1.83333 10.375 4.70833 5.58335 11.4167 4.62502Z"
        fill="white"
      />
    </svg>
  )
}

export default ShareIcon
