import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Spin,
} from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import Image from '../../../assets/icons/Image'
import Check from '../../../assets/icons/Check'
import Garbage from '../../../assets/icons/Garbage'
import ImagePopover from './ImagePopover'
import Answer from './Answer'
import ShowPreview from './ShowPreview'
import {
  useCreateAndEditQuestion,
  useDeleteImage,
  useDeleteQuestion,
} from '../../../queries/mutation'
import { Question } from '../../../models/test-page/Question'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../hooks/redux'
import { removeQuestionFromList } from '../../../store/reducers/questionListSlice'
import { isFile } from '../../../utils/helpers/isFile'

export interface FormInterface {
  question: string
  A: string
  B: string
  C: string
  D: string
}

export interface ImageI {
  question: File | string
  A: File | string
  B: File | string
  C: File | string
  D: File | string
}

const imageInitialState = {
  question: '',
  A: '',
  B: '',
  C: '',
  D: '',
}

export enum QuestionEnum {
  question = 'question',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

interface TestProps {
  question?: Question
  number: number
}

const Test: React.FC<TestProps> = ({ question, number }) => {
  const [form] = Form.useForm<FormInterface>()
  const { test_id } = useParams<{ test_id: string }>()
  const [showPreview, setShowPreview] = useState(false)
  const [testChange, setTestChange] = useState(true)
  const imageInput = useRef<HTMLInputElement | undefined>()
  const [images, setImages] = useState<ImageI>(imageInitialState)
  const [checkedAnswer, setCheckedAnswer] = useState(1)

  const dispatch = useAppDispatch()

  const createQuestion = useCreateAndEditQuestion(
    question?.question?.id || 0,
    setTestChange,
    dispatch
  )
  const deleteQuestionMutation = useDeleteQuestion()
  const deleteImageMutation = useDeleteImage()

  const currentAnswer = () => {
    if (question?.answers.a.is_correct === 1) return 1
    if (question?.answers.b.is_correct === 1) return 2
    if (question?.answers.c.is_correct === 1) return 3
    if (question?.answers.d.is_correct === 1) return 4
    return 0
  }

  useEffect(() => {
    if (question?.question && question.question.id !== 0) {
      form.setFieldsValue({
        question: question.question?.name,
        A: question.answers?.a?.name,
        B: question.answers?.b?.name,
        C: question.answers?.c?.name,
        D: question.answers?.d?.name,
      })
      setImages({
        question: question.question?.image || '',
        A: question.answers?.a?.image || '',
        B: question.answers?.b?.image || '',
        C: question.answers?.c?.image || '',
        D: question.answers?.d?.image || '',
      })
      setCheckedAnswer(currentAnswer())
      setTestChange(false)
    }
    // eslint-disable-next-line
  }, [question])

  const handleOnFinish = (val: FormInterface) => {
    const formData = new FormData()
    const newQuestion: Question = {
      question: {
        name: val.question,
        id: question?.question.id === 0 ? undefined : question?.question.id,
      },
      answers: {
        a: {
          name: val.A,
          is_correct: checkedAnswer === 1 ? 1 : 0,
        },
        b: {
          name: val.B,
          is_correct: checkedAnswer === 2 ? 1 : 0,
        },
        c: {
          name: val.C,
          is_correct: checkedAnswer === 3 ? 1 : 0,
        },
        d: {
          name: val.D,
          is_correct: checkedAnswer === 4 ? 1 : 0,
        },
      },
    }
    formData.append('question', JSON.stringify(newQuestion))
    formData.append('id', test_id)
    for (let key in images) {
      if (images[key as QuestionEnum]) {
        switch (key) {
          case 'question':
            if (isFile(images[key as QuestionEnum]))
              formData.append('q', images[key as QuestionEnum])
            break
          case 'A':
            if (isFile(images[key as QuestionEnum]))
              formData.append('a', images[key as QuestionEnum])
            break
          case 'B':
            if (isFile(images[key as QuestionEnum]))
              formData.append('b', images[key as QuestionEnum])
            break
          case 'C':
            if (isFile(images[key as QuestionEnum]))
              formData.append('c', images[key as QuestionEnum])
            break
          case 'D':
            if (isFile(images[key as QuestionEnum]))
              formData.append('d', images[key as QuestionEnum])
            break
          default:
            console.log('something went wrong')
            break
        }
      }
    }
    createQuestion.mutate(formData)
  }

  const deleteQuestion = async () => {
    if (typeof question?.question.id === 'number') {
      if (question.question.id === 0) {
        message.success("Savol o'chirildi")
        form.resetFields()
        dispatch(
          removeQuestionFromList({
            id: question.question.id,
            isNewItems: false,
          })
        )
        return
      }
      await deleteQuestionMutation.mutateAsync(question.question.id)
      form.resetFields()
      dispatch(removeQuestionFromList({ id: question.question.id }))
      message.success("Savol o'chirildi")
    }
  }

  const changeShowPreview = (e: CheckboxChangeEvent) => {
    setShowPreview(e.target.checked)
  }

  const handleTestChange = (change: boolean) => {
    setTestChange(change)
    setShowPreview(false)
  }

  const handleAnswerRadioButton = (e: RadioChangeEvent) => {
    setCheckedAnswer(e.target.value)
  }

  const handleImageSelect: (
    e: ChangeEvent<HTMLInputElement>,
    name: QuestionEnum
  ) => void = (e, name) => {
    const files = e.target.files
    if (files?.length === 1) {
      if (files[0].size > 2 * 1024 * 1024) {
        message.error(`Rasm uchun maksimal hajm 2 MegaBayt!`)
        return
      }
      setTestChange(true)
      setImages({
        ...images,
        [name]: files[0],
      })
    } else {
      message.error(`Bittadan ko'p fayl tanlangan!`)
    }
  }

  const deleteImage = (params: string) => {
    deleteImageMutation.mutate(params)
  }

  const handleImageRemove = (type: QuestionEnum) => {
    switch (type) {
      case QuestionEnum.question:
        if (
          !isFile(images.question) &&
          typeof images.question === 'string' &&
          images.question !== ''
        ) {
          deleteImage(`?question_id=${question?.question.id}`)
        }
        setImages({
          ...images,
          question: '',
        })
        break
      case QuestionEnum.A:
        if (!isFile(images.A) && typeof images.A === 'string' && images.A !== '') {
          deleteImage(`?option_id=${question?.answers.a.id}`)
        }
        setImages({
          ...images,
          A: '',
        })
        break
      case QuestionEnum.B:
        if (!isFile(images.B) && typeof images.B === 'string' && images.B !== '') {
          deleteImage(`?option_id=${question?.answers.b.id}`)
        }
        setImages({
          ...images,
          B: '',
        })
        break
      case QuestionEnum.C:
        if (!isFile(images.C) && typeof images.C === 'string' && images.C !== '') {
          deleteImage(`?option_id=${question?.answers.c.id}`)
        }
        setImages({
          ...images,
          C: '',
        })
        break
      case QuestionEnum.D:
        if (!isFile(images.D) && typeof images.D === 'string' && images.D !== '') {
          deleteImage(`?option_id=${question?.answers.d.id}`)
        }
        setImages({
          ...images,
          D: '',
        })
        break
    }
  }

  const imageSrc = (image: File | string) => {
    let src
    if (typeof image === 'string') src = image
    else {
      src = URL.createObjectURL(image)
    }
    return src
  }

  return (
    <>
      <div className={'test_list_preview d-flex align-center'}>
        <Checkbox onChange={changeShowPreview} checked={showPreview} />
        <span className={'test_list_preview_label'}> Prevyuni ko’rsatish</span>
      </div>
      <Form form={form} onFinish={handleOnFinish} name="basic">
        <Row>
          <Col span={19} className={'d-flex question'}>
            <span className={'question_number'}>{number}</span>
            <Form.Item
              name="question"
              rules={[{ required: true, message: 'Savol kiritilmagan!' }]}
            >
              <Input.TextArea
                autoSize
                onChange={() => handleTestChange(true)}
                placeholder={'Savol matnini kiriting...'}
              />
            </Form.Item>
          </Col>
          <Col span={4} className={'test_list_actions'}>
            <Row>
              <Col className={'test_list_actions_item'}>
                <input
                  accept={'image/*'}
                  ref={imageInput as any}
                  onChange={(e) => handleImageSelect(e, QuestionEnum.question)}
                  type={'file'}
                  style={{ display: 'none' }}
                  id={'save_image'}
                />
                <Popover
                  content={
                    <ImagePopover
                      handleImageRemove={handleImageRemove}
                      type={QuestionEnum.question}
                      image={images.question}
                    />
                  }
                >
                  {images.question ? (
                    <img
                      onClick={() => {
                        imageInput.current?.click()
                      }}
                      style={{
                        width: '30px',
                        height: '30px',
                        cursor: 'pointer',
                      }}
                      src={imageSrc(images.question)}
                      alt={'question'}
                    />
                  ) : (
                    <Button
                      onClick={() => {
                        imageInput.current?.click()
                      }}
                      className={'border-5'}
                      icon={<Image />}
                      type={'primary'}
                    />
                  )}
                </Popover>
              </Col>
              <Col className={'test_list_actions_item'} span={8}>
                <Spin spinning={createQuestion.isLoading}>
                  {testChange ? (
                    <Button
                      htmlType={'submit'}
                      title={'Saqlash'}
                      className={'test_list_actions_item_ok border-5'}
                    >
                      OK
                    </Button>
                  ) : (
                    <Button
                      title={'Saqlangan'}
                      className={'test_list_actions_item_check border-5'}
                      icon={<Check />}
                      type={'primary'}
                    />
                  )}
                </Spin>
              </Col>
              <Col className={'test_list_actions_item'} span={8}>
                <Popconfirm
                  okText={'Tasdiqlash'}
                  cancelText={'Ortga'}
                  onConfirm={deleteQuestion}
                  title={"Savolni o'chirish"}
                >
                  <Button
                    title={"O'chirish"}
                    className={'test_list_actions_item_danger border-5'}
                    icon={<Garbage />}
                    danger
                  />
                </Popconfirm>
              </Col>
            </Row>
          </Col>
        </Row>
        {showPreview && <ShowPreview text={form.getFieldValue('question')} />}
        <Radio.Group
          style={{ width: '100%' }}
          name="radio_group"
          onChange={handleAnswerRadioButton}
          value={checkedAnswer}
        >
          <Answer
            form={form}
            handleImageRemove={handleImageRemove}
            handleImageSelect={handleImageSelect}
            handleTestChange={handleTestChange}
            image={images.A}
            showPreview={showPreview}
            name={QuestionEnum.A}
            radioNumber={1}
          />
          <Answer
            form={form}
            handleImageRemove={handleImageRemove}
            handleImageSelect={handleImageSelect}
            handleTestChange={handleTestChange}
            showPreview={showPreview}
            name={QuestionEnum.B}
            image={images.B}
            radioNumber={2}
          />
          <Answer
            form={form}
            handleImageRemove={handleImageRemove}
            handleImageSelect={handleImageSelect}
            handleTestChange={handleTestChange}
            showPreview={showPreview}
            image={images.C}
            name={QuestionEnum.C}
            radioNumber={3}
          />
          <Answer
            form={form}
            handleImageRemove={handleImageRemove}
            handleImageSelect={handleImageSelect}
            handleTestChange={handleTestChange}
            showPreview={showPreview}
            image={images.D}
            name={QuestionEnum.D}
            radioNumber={4}
          />
        </Radio.Group>
      </Form>
    </>
  )
}

function testPropsAreEqual(prevProps: TestProps, nextProps: TestProps) {
  return prevProps.question === nextProps.question
}

export default React.memo(Test, testPropsAreEqual)
