import React, { ChangeEvent, useRef } from 'react'
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Popover,
  Radio,
  Row,
} from 'antd'
import ImagePopover from './ImagePopover'
import Image from '../../../assets/icons/Image'
import ShowPreview from './ShowPreview'
import { FormInterface, QuestionEnum } from './Test'

interface AnswerProps {
  name: QuestionEnum
  radioNumber: number
  form: FormInstance<FormInterface>
  showPreview: boolean
  handleTestChange: (change: boolean) => void
  handleImageSelect: (
    e: ChangeEvent<HTMLInputElement>,
    name: QuestionEnum
  ) => void
  image: string | File
  handleImageRemove: (type: QuestionEnum) => void
}

const imageSrc = (image: File | string) => {
  let src
  if (typeof image === 'string') src = image
  else {
    src = URL.createObjectURL(image)
  }
  return src
}

const Answer: React.FC<AnswerProps> = ({
  name,
  radioNumber,
  showPreview,
  handleTestChange,
  handleImageSelect,
  image,
  handleImageRemove,
  form,
}) => {
  const imageInput = useRef<HTMLInputElement | undefined>()

  return (
    <div>
      <Row>
        <Col span={19} className={'d-flex answer'}>
          <span className={'answer_number'}>{name}</span>
          <Form.Item
            name={`${name}`}
            rules={[{ required: true, message: 'Savol kiritilmagan!' }]}
          >
            <Input.TextArea
              autoSize
              onChange={() => handleTestChange(true)}
              name={name}
              placeholder={'Javob matnini kiriting...'}
            />
          </Form.Item>
        </Col>
        <Col span={4} className={'test_list_actions_answer'}>
          <Row>
            <Col className={'test_list_actions_answer_item'} span={8}>
              <input
                ref={imageInput as any}
                type={'file'}
                accept={'image/*'}
                onChange={(e) => handleImageSelect(e, name)}
                style={{ display: 'none' }}
              />
              <Popover
                content={
                  <ImagePopover
                    type={name}
                    handleImageRemove={handleImageRemove}
                    image={image}
                  />
                }
              >
                {image ? (
                  <img
                    onClick={() => {
                      imageInput.current?.click()
                    }}
                    style={{
                      width: '30px',
                      height: '30px',
                      cursor: 'pointer',
                    }}
                    src={imageSrc(image)}
                    alt={'question'}
                  />
                ) : (
                  <Button
                    onClick={() => {
                      imageInput.current?.click()
                    }}
                    className={'border-5'}
                    icon={<Image />}
                    type={'primary'}
                  />
                )}
              </Popover>
            </Col>
            <Col className={'test_list_actions_item'} span={8}>
              <Radio value={radioNumber} />
            </Col>
          </Row>
        </Col>
      </Row>
      {showPreview && <ShowPreview text={form.getFieldValue(name)} />}
    </div>
  )
}

function answerPropsAreEqual(prevAnswer: AnswerProps, nextAnswer: AnswerProps) {
  return (
    prevAnswer.image === nextAnswer.image &&
    prevAnswer.showPreview === nextAnswer.showPreview
  )
}

export default React.memo(Answer, answerPropsAreEqual)
