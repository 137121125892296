import { Button, Form, Select } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import SwiperCore, { A11y, Mousewheel, Navigation } from 'swiper'
import 'swiper/components/navigation/navigation.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import RightDownIcon from '../../assets/icons/RightDownIcon'
import SelectIcon from '../../assets/icons/SelectIcon'
import { useAppSelector } from '../../hooks/redux'
import { StudentGroup } from '../../models/detailedGroup/StudentsData'
import { DirectionDataElement } from '../../models/direction/DirectionData'
import { SubjectTestDataElement } from '../../models/subject-test/SubjectTestData'
import { DTMFormValues, DTMPoyloadData } from '../../models/test-page/DTMData'
import { Years } from '../../models/years/Years'
import { useStartDTMTest } from '../../queries/mutation'
import {
  useActiveGroups,
  useDirection,
  useSubjectTest,
  useYears,
} from '../../queries/queries'
import { DatePicker } from '../../services/DatePicker'

SwiperCore.use([Navigation, Mousewheel, A11y])

const { Option } = Select
const { Item } = Form

const DTMtest = () => {
  const { data: years, isFetched: yearIsFetched } = useYears()
  const [active, setActive] = useState<Years>()
  useEffect(() => {
    setActive(yearIsFetched && years[0])
  }, [years, yearIsFetched])
  const [directionList, setDirectionList] = useState([])
  const defaultGroups = useAppSelector(
    (state) => state.selectedGroupsReducer.selectedGroups
  )
  const { data: direction } = useDirection()
  const [form] = Form.useForm()
  const startDTMTest = useStartDTMTest(form)

  const directionChnage = (e: any) => {
    setDirectionList(e.split(' va '))
  }

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  const { data: subjectTest } = useSubjectTest()
  const { data: studentGroup } = useActiveGroups()

  const VirtualTime = () => {
    return (
      <div className="virtualExam_time">
        <h1>
          Qo'shimcha sozlamalar&nbsp; <RightDownIcon />
        </h1>
        <div>
          <div>
            <p>Boshlanish vaqti</p>
            <Item
              name="start_at"
              rules={[{ required: true, message: 'Boshlanish vaqtini kiriting!' }]}
            >
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                className="virtual_input"
                format="YYYY-MM-DD HH:mm"
              />
            </Item>
          </div>
        </div>
        <div>
          <div>
            <p>Yakunlash vaqti</p>
            <Item
              name="finish_at"
              rules={[{ required: true, message: 'Yakunlash vaqtini kiriting!' }]}
            >
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                className="virtual_input"
              />
            </Item>
          </div>
        </div>
      </div>
    )
  }

  // initial values
  useEffect(() => {
    form.setFieldsValue({
      group_ids: defaultGroups,
      start_at: dayjs(),
      finish_at: dayjs().add(1, 'day'),
    })
  }, [form, defaultGroups])

  // on finish
  const onFinish = (values: DTMFormValues) => {
    let data: DTMPoyloadData = {
      group_ids: values.group_ids,
      year_id: active?.id!,
      start_at: values.start_at.format('YYYY-MM-DD HH:mm'),
      finish_at: values.finish_at.format('YYYY-MM-DD HH:mm'),
    }
    if (values.course_id) {
      data = { ...data, course_id: values.course_id }
    } else if (values.blok_1 && values.blok_2 && values.blok_3) {
      data = {
        ...data,
        blok_1: values.blok_1,
        blok_2: values.blok_2,
        blok_3: values.blok_3,
      }
    }

    startDTMTest.mutate(data)
  }

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <div className="Mswiper">
          <span className="prev" ref={navigationPrevRef}>
            <svg
              width="5"
              height="10"
              viewBox="0 0 5 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.5 1.25L0.75 5L4.5 8.75"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <Swiper
            spaceBetween={5}
            slidesPerView={'auto'}
            slidesPerGroup={10}
            speed={1000}
            mousewheel
            freeMode
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onSwiper={(swiper: any) => {
              setTimeout(() => {
                if (swiper.params) {
                  swiper.params.navigation.prevEl = navigationPrevRef.current
                  swiper.params.navigation.nextEl = navigationNextRef.current
                }
                swiper.navigation.destroy()
                swiper.navigation.init()
                swiper.navigation.update()
              })
            }}
          >
            {years?.map((years: Years) => (
              <SwiperSlide key={years.id}>
                <button
                  onClick={() => setActive(years)}
                  className={years.name === active?.name ? 'active' : ''}
                >
                  {years.name}
                </button>
              </SwiperSlide>
            ))}
          </Swiper>
          <span className="next" ref={navigationNextRef}>
            <svg
              width="5"
              height="10"
              viewBox="0 0 5 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 1.25L4.25 5L0.5 8.75"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
        <div className="virtualExam">
          {active?.name === '2021' ? (
            <div className="compulsory_sciences">
              <div className="virtualExam_title">
                <h1 className="virtualExam_title_h1">Majburiy fanlar majmuasi</h1>
              </div>
              <div className="virtualExam_list">
                <div>
                  <p>Ona&nbsp;tili</p>
                  <span></span>
                  <p>10&nbsp;ta</p>
                </div>
                <div>
                  <p>Matematika</p>
                  <span></span>
                  <p>10&nbsp;ta</p>
                </div>
                <div>
                  <p>O'zbekiston&nbsp;tarixi</p>
                  <span></span>
                  <p>10&nbsp;ta</p>
                </div>
              </div>
              <VirtualTime />
            </div>
          ) : (
            <div className="compulsory_sciences" style={{ width: 'auto' }}>
              <div className="science_list">
                <div>
                  <h1 className="virtualExam_title_h1">Birinchi fan</h1>
                  <Item
                    name="blok_1"
                    rules={[
                      {
                        required: true,
                        message: 'Birinchi fanni tanlang!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && getFieldValue('blok_2') === value) {
                            return Promise.reject(
                              new Error('Bir xil fan tanlamang!')
                            )
                          }
                          if (value && getFieldValue('blok_3') === value) {
                            return Promise.reject(
                              new Error('Bir xil fan tanlamang!')
                            )
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Tanlash"
                      className="custom_Select"
                      style={{ width: 264, margin: '8px 0' }}
                      suffixIcon={<SelectIcon />}
                    >
                      {subjectTest?.subject.map((e: SubjectTestDataElement) => (
                        <Option key={e.id} value={e.id}>
                          {e.name}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                </div>
                <div>
                  <h1 className="virtualExam_title_h1">Ikkinchi fan</h1>
                  <Item
                    name="blok_2"
                    rules={[
                      {
                        required: true,
                        message: 'Ikkinchi fanni tanlang!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && getFieldValue('blok_3') === value) {
                            return Promise.reject(
                              new Error('Bir xil fan tanlamang!')
                            )
                          }
                          if (value && getFieldValue('blok_1') === value) {
                            return Promise.reject(
                              new Error('Bir xil fan tanlamang!')
                            )
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Tanlash"
                      className="custom_Select"
                      style={{ width: 264, margin: '8px 0' }}
                      suffixIcon={<SelectIcon />}
                    >
                      {subjectTest?.subject.map((e: SubjectTestDataElement) => (
                        <Option key={e.id} value={e.id}>
                          {e.name}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                </div>
                <div>
                  <h1 className="virtualExam_title_h1">Uchinchi fan</h1>
                  <Item
                    name="blok_3"
                    // rules={[{ required: true, message: 'Uchinchi fanni tanlang!' }]}
                    rules={[
                      {
                        required: true,
                        message: 'Uchinchi fanni tanlang!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && getFieldValue('blok_2') === value) {
                            return Promise.reject(
                              new Error('Bir xil fan tanlamang!')
                            )
                          }
                          if (value && getFieldValue('blok_1') === value) {
                            return Promise.reject(
                              new Error('Bir xil fan tanlamang!')
                            )
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Tanlash"
                      className="custom_Select"
                      style={{ width: 264, margin: '8px 0' }}
                      suffixIcon={<SelectIcon />}
                    >
                      {subjectTest?.subject.map((e: SubjectTestDataElement) => (
                        <Option key={e.id} value={e.id}>
                          {e.name}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                </div>
              </div>
              <VirtualTime />
            </div>
          )}

          <div className="elective_sciences">
            {active?.name === '2021' ? (
              <>
                <div className="virtualExam_title">
                  <h1 className="virtualExam_title_h1">Tanlov fanlar majmuasi</h1>
                </div>
                <Item
                  name="course_id"
                  rules={[{ required: true, message: 'Fanlar majmuasini tanlang!' }]}
                >
                  <Select
                    showSearch
                    placeholder={'Tanlash'}
                    className="custom_Select"
                    style={{ width: 264, margin: '8px 0' }}
                    suffixIcon={<SelectIcon />}
                    onChange={(_: number, option: any) => {
                      directionChnage(option.children)
                    }}
                  >
                    {direction?.directions.map((e: DirectionDataElement) => (
                      <Option key={e.id} value={e.id}>
                        {e.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
                <div className="virtualExam_list">
                  {directionList.length <= 0 ? (
                    <>
                      <div>
                        <span></span>
                      </div>
                    </>
                  ) : (
                    <>
                      {directionList.map((e: string, i: number) => (
                        <div key={i}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: e.replace(' ', '&nbsp;'),
                            }}
                          />
                          <span></span>
                          <p>20&nbsp;ta</p>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}

            <div>
              <h1 className="virtualExam_title_h1" style={{ marginTop: 18 }}>
                Guruhni biriktirish
              </h1>
              <Item
                name="group_ids"
                rules={[{ required: true, message: 'Guruh biriktiring!' }]}
              >
                <Select
                  showSearch
                  placeholder="Tanlash"
                  mode="multiple"
                  className="custom_Select"
                  style={{ width: 264, margin: '8px 0' }}
                  suffixIcon={<SelectIcon />}
                >
                  {studentGroup?.map((e: StudentGroup) => (
                    <Option key={e.id} value={e.id}>
                      {e.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </div>
            <Button
              loading={startDTMTest.isLoading}
              htmlType="submit"
              type="primary"
              style={{ float: 'right' }}
            >
              Boshlash
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default DTMtest
