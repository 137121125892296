import TestingStudents from '../components/detailedGroup/TestingStudents'
import DetailedGroup from '../pages/detailedGroup/DetailedGroup'
import EditTest from '../pages/editTest/EditTest'
import MyGroup from '../pages/myGroup/MyGroup'
import Profil from '../pages/profilPage/Profil'
import Tests from '../pages/tests/Tests'
import { rootPaths } from './rootPaths'

export const rootRoutes = [
  {
    path: rootPaths.myGroup,
    component: MyGroup,
    exact: true,
  },
  {
    path: rootPaths.detailedGroup,
    component: DetailedGroup,
    exact: true,
  },
  {
    path: rootPaths.testingStudents,
    component: TestingStudents,
    exact: true,
  },
  {
    path: rootPaths.startTest + rootPaths.editTest,
    component: EditTest,
    exact: true,
  },
  {
    path: rootPaths.startTest,
    component: Tests,
    exact: false,
  },
  {
    path: rootPaths.myPage,
    component: Profil,
    exact: true,
  },
]
