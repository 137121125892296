import { Button, Form, Input, message, Popover } from 'antd'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { TelegramShareButton } from 'react-share'
import CancelIcon from '../../assets/icons/CancelIcon'
import FileIcon from '../../assets/icons/FileIcon'
import PlusCircleIcon from '../../assets/icons/PlusCircleIcon'
import SearchIcon from '../../assets/icons/SearchIcon'
import ShareIcon from '../../assets/icons/ShareIcon'
import TelegramIcon from '../../assets/icons/TelegramIcon'
import { rootPaths } from '../../routing/rootPaths'
import UniversalHeader from '../common/universal-header/UniversalHeader'
import { selectedGroupsSlice } from '../../store/reducers/SelectedGroupsSlice'
import { useAppDispatch } from '../../hooks/redux'
import { URLS } from '../../utils/constants/urls'

interface Props {
  title: string
  setModalVisible: (visible: boolean) => void
  setModalData: (data: { type: string; student_id?: number }) => void
  setSearchText: (text: string) => void
  searchText: string
  group_link: string
  setDefaultActiveKey: (key: string) => void
}

const StudentsHeader: React.FC<Props> = ({
  title,
  setModalVisible,
  setModalData,
  setSearchText,
  searchText,
  group_link,
  setDefaultActiveKey,
}) => {
  const [form] = Form.useForm()
  const timerRef = React.useRef<any>()
  const { push } = useHistory()
  const { setSelectedGroups } = selectedGroupsSlice.actions
  const params = useParams<{ group_id: string }>()
  const dispatch = useAppDispatch()

  // create student
  const createStudent = () => {
    setDefaultActiveKey('students')
    setModalData({ type: 'create' })
    setModalVisible(true)
  }

  // input suffix
  const inputSuffix = () => {
    const onCancel = () => {
      setSearchText('')
      form.resetFields()
    }
    if (searchText) {
      return (
        <span className="suffix" onClick={onCancel}>
          <CancelIcon />
        </span>
      )
    } else {
      return <SearchIcon />
    }
  }

  // copy group link
  const copyLink = async () => {
    await navigator?.clipboard?.writeText(`${URLS.AKAM_UZ}/guruhlarim/${group_link}`)
    message.success("Guruh havolasidan nusxa ko'chirildi!")
  }

  // start test
  const startTest = () => {
    dispatch(setSelectedGroups([Number(params.group_id)]))
    push(`${rootPaths.startTest + rootPaths.myTest}`)
    message.success('Guruh tanlandi, testni boshlashingiz mumkin!')
  }

  // popover content
  const popoverContent = () => {
    return (
      <>
        <Input disabled value={`${URLS.AKAM_UZ}/guruhlarim/${group_link}`} />
        <p onClick={copyLink}>Nusxalash</p>
        <p>
          <TelegramShareButton url={`${URLS.AKAM_UZ}/guruhlarim/${group_link}`}>
            Telegramdan jo'natish&nbsp;
            <TelegramIcon />
          </TelegramShareButton>
        </p>
      </>
    )
  }

  return (
    <>
      <UniversalHeader
        backBtn={true}
        title={title}
        button={
          <div className="studentHeader">
            <Form form={form}>
              <Form.Item name="searchText" className="search">
                <Input
                  bordered={false}
                  placeholder="Qidirish"
                  suffix={inputSuffix()}
                  onChange={(e) => {
                    clearTimeout(timerRef.current)
                    timerRef.current = setTimeout(() => {
                      setSearchText(e.target.value.length > 2 ? e.target.value : '')
                    }, 500)
                  }}
                />
              </Form.Item>
            </Form>
            <Button className="white_blue_btn startTest" onClick={startTest}>
              <FileIcon />
              Test o'tkazish
            </Button>
            <Button
              className="blue_ant_button"
              type="primary"
              onClick={createStudent}
            >
              <PlusCircleIcon />
              O'quvchi qo'shish
            </Button>
            <Popover
              placement="bottomLeft"
              content={popoverContent}
              trigger={'click hover'}
              overlayClassName="sharePopover"
              mouseEnterDelay={2}
              mouseLeaveDelay={2}
            >
              <Button className="blue_ant_button" type="primary">
                <ShareIcon />
                Ulashish
              </Button>
            </Popover>
          </div>
        }
      />
    </>
  )
}

export default StudentsHeader
