import { message } from 'antd'
import axios from 'axios'
import { AuthService } from '../services/AuthService'

const $api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
})

$api.interceptors.request.use((config) => {
  const token = AuthService.getToken()
  if (token && config.headers) {
    config.headers.Authorization = ''
  }
  return config
})

$api.interceptors.response.use(undefined, (error) => {
  if (error.message === 'Network Error') {
    message.error("Internet tarmog'iga ulanishda xatolik yuz berdi")
  } else {
    message.error(error.response.data.message)
  }
  if (error.response?.status === 401) {
    AuthService.clearToken()
  }
  return Promise.reject(error)
})

export default $api
