import React from "react";

export default function SearchIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.27001 14.5392C8.88302 14.5389 10.4495 13.9989 11.7202 13.0053L15.715 17L17 15.7151L13.0051 11.7204C13.9993 10.4498 14.5396 8.88296 14.54 7.26962C14.54 3.26133 11.2785 0 7.27001 0C3.26151 0 0 3.26133 0 7.26962C0 11.2779 3.26151 14.5392 7.27001 14.5392ZM7.27001 1.8174C10.2771 1.8174 12.7225 4.26272 12.7225 7.26962C12.7225 10.2765 10.2771 12.7218 7.27001 12.7218C4.26295 12.7218 1.8175 10.2765 1.8175 7.26962C1.8175 4.26272 4.26295 1.8174 7.27001 1.8174Z"
        fill="#0C4292"
      />
    </svg>
  );
}
