import React from 'react'

const Image = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 5.5V19.5H5.5V5.5H19.5ZM19.5 3.5H5.5C4.4 3.5 3.5 4.4 3.5 5.5V19.5C3.5 20.6 4.4 21.5 5.5 21.5H19.5C20.6 21.5 21.5 20.6 21.5 19.5V5.5C21.5 4.4 20.6 3.5 19.5 3.5ZM14.64 12.36L11.64 16.23L9.5 13.64L6.5 17.5H18.5L14.64 12.36Z"
        fill="white"
      />
    </svg>
  )
}

export default Image;
