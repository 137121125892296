import React from 'react'

const Filtr = () => {
  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="1" x2="22" y2="1" stroke="#0C4292" strokeWidth="2" />
      <line x1="5" y1="7" x2="16" y2="7" stroke="#0C4292" strokeWidth="2" />
      <line x1="8" y1="13" x2="13" y2="13" stroke="#0C4292" strokeWidth="2" />
    </svg>
  )
}

export default Filtr
