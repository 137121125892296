import { Button, Form, Input, message, Radio, Select, Spin } from 'antd'
import React, { ChangeEventHandler, useEffect, useState } from 'react'
import './Profil.scss'
import {
  useAddress,
  useAddressDistrict,
  useMyPage,
  useSubjcet,
} from '../../queries/queries'
import { useUpdateUser } from '../../queries/mutation'
import { UserFormFields } from '../../models/user/User'
import InputMask from 'react-input-mask'
import dayjs from 'dayjs'
import Photograph from '../../assets/icons/Photograph'

const Profil = () => {
  const { Item } = Form

  const { Option } = Select
  const [id, setId] = useState<number>()

  const { data, isLoading } = useMyPage()
  const [form] = Form.useForm()
  const { data: addressData } = useAddress()
  const { data: addressDistrict, isLoading: districtLoading } = useAddressDistrict(
    id! || data?.region?.id!
  )
  const { data: subjectMyPage } = useSubjcet()
  const updateUserMutation = useUpdateUser()
  const [userPhoto, setUserPhoto] = useState<{
    asFile: File | null
    url: string
  }>({
    asFile: null,
    url: data?.image || '',
  })

  // change region
  const changeRegion = (region_id: number) => {
    setId(region_id)
    form.setFieldsValue({
      district: '',
    })
  }

  // initial data
  useEffect(() => {
    const initialSubject = () => {
      if (data?.subject_id) {
        return Number(data?.subject_id)
      } else {
        return ''
      }
    }
    if (data) {
      form.setFieldsValue({
        full_name: data.full_name,
        phone: data.phone,
        birthday: dayjs(data.birthday, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        email: data.email,
        region: data.region?.name,
        gender: data.gender,
        subject: initialSubject(),
        district: data?.district?.id,
      })
    }

    if (!userPhoto.url) {
      setUserPhoto({ ...userPhoto, url: data?.image! })
    }
  }, [data, form, userPhoto])

  // handle user photo
  const handleUserPhoto: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files
    if (files) {
      if (!files?.[0]) {
        return
      }
      if (files.length > 0 && files[0].size > 1024 * 2 * 1024) {
        message.error('Rasm uchun maksimal hajm 2 Megabayt!')
        return
      }
      const imageUrl = URL.createObjectURL(files[0])
      setUserPhoto({ asFile: files[0], url: imageUrl })
      return
    }
  }

  // on finish
  const onFinish = (fields: UserFormFields) => {
    const formData = new FormData()

    // user photo
    if (userPhoto.asFile && userPhoto.url) {
      formData.append('image', userPhoto.asFile)
    }

    // user data (info)
    const dataUpdate = {
      full_name: fields.full_name,
      email: fields.email,
      gender: fields.gender,
      address_id: fields.district,
      subject: fields.subject,
      birthday: dayjs(fields.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    }

    formData.append('data', JSON.stringify(dataUpdate))

    // user phone
    formData.append('phone', JSON.stringify(fields.phone))

    updateUserMutation.mutate(formData)
  }

  return (
    <div className="profilPage component_container">
      <Spin spinning={isLoading || districtLoading}>
        <div className="title">
          <h1>Mening sahifam</h1>
        </div>
        <Form
          className="formProfil"
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <div className="formProfil_left">
            <Item className="profilImage" name="image">
              <label>
                <div className="imgTag">
                  <img src={userPhoto.url} className="imgTag" alt="profilImage" />
                  <div className="hoverImage">
                    <Photograph />
                  </div>
                </div>
                <Input
                  id="userPhoto"
                  type="file"
                  onChange={handleUserPhoto}
                  accept="image/*"
                />
              </label>
            </Item>

            <Item className="userName">
              <h2>{data?.full_name}</h2>
            </Item>
            {data?.subject_name && (
              <Item className="subjectName">
                <div>
                  <p>{data?.subject_name}</p>
                </div>
              </Item>
            )}
          </div>
          <div className="formProfil_right">
            <Item
              className="FIOInput inputItem"
              label="F.I.SH"
              name="full_name"
              rules={[{ required: true, message: 'Ism familiya kiritilmagan' }]}
            >
              <Input size="large" />
            </Item>
            <div className="formProfil_right_center">
              <Item
                label="Telefon raqam"
                name={'phone'}
                className="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: 'Telefon raqamingizni kiriting',
                  },
                ]}
              >
                <InputMask disabled mask="+\9\98 99 999 99 99">
                  {() => <Input size="large" />}
                </InputMask>
              </Item>
              <Item label="Tug'ilgan sana" className="dateBirth" name={'birthday'}>
                {/* <DatePicker
                  size="large"
                  className="datePicker"
                  format="DD.MM.YYYY"
                /> */}
                <InputMask mask={'99.99.9999'}>
                  {() => <Input size="large" />}
                </InputMask>
              </Item>
            </div>
            <div className="formProfil_right_center">
              <Item name={'gender'} className="gender" label="Jinsi">
                <Radio.Group>
                  <Radio value={'erkak'}>Erkak</Radio>
                  <Radio value={'ayol'}>Ayol</Radio>
                </Radio.Group>
              </Item>
              <Item
                label="E-mail"
                name={'email'}
                className="email"
                // rules={[
                //   {
                //     type: "email",
                //     required: true,
                //     message: "E-mail to'g'ri kiriting",
                //   },
                // ]}
              >
                <Input size="large" />
              </Item>
            </div>
            <div className="formProfil_right_center">
              <Item label="Viloyat" name="region" className="region">
                <Select onChange={changeRegion} size="large">
                  {addressData?.map((item) => {
                    return (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    )
                  })}
                </Select>
              </Item>
              <Item label="Tuman(Shahar)" name={'district'} className="district">
                <Select size="large">
                  {addressDistrict?.map((item) => {
                    return (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    )
                  })}
                </Select>
              </Item>
            </div>

            <Item label="Fan" name={'subject'} className="subject">
              <Select size="large">
                {subjectMyPage?.map((item) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  )
                })}
              </Select>
            </Item>
            <Item className="submitBTN">
              <Button type="primary" className="blue_ant_button" htmlType="submit">
                SAQLASH
              </Button>
            </Item>
          </div>
        </Form>
      </Spin>
    </div>
  )
}

export default Profil
