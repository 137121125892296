import { Pagination, Popconfirm, Popover, Select, Table } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import MoreIcon from '../../assets/icons/MoreIcon'
import { AttachedTestData } from '../../models/detailedGroup/AttachedTestsData'
import { useDeleteAttachedTest } from '../../queries/mutation'
import { useAttachedTests } from '../../queries/queries'
import { rootPaths } from '../../routing/rootPaths'

interface IProps {
  group_id: number
  searchText: string
}

const TestsOfGroup: React.FC<IProps> = ({ group_id, searchText }) => {
  const { Column } = Table
  const history = useHistory()
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const { data, isLoading } = useAttachedTests(group_id, page, pageSize, searchText)
  const deleteAttachedTest = useDeleteAttachedTest()

  // delete test
  const deleteTest = (
    record: AttachedTestData,
    e: React.MouseEvent<HTMLElement, MouseEvent> | undefined
  ) => {
    e?.stopPropagation()
    const info = {
      group_id,
      shablon_id: record.shablon_id,
    }
    deleteAttachedTest.mutate(info)
  }

  // on row click
  const onRowClick = (record: AttachedTestData) => {
    history.push(`${rootPaths.myGroup}/${group_id}/${record.shablon_id}`)
  }

  // handle status
  const handleStatus = (record: AttachedTestData) => {
    if (record.student_count === record.finish_student_counts) {
      return 'all_worked'
    } else if (record.finish_student_counts === 0) {
      return 'not_working'
    } else if (record.finish_student_counts > 0) {
      return 'working'
    }
  }

  // pagination
  const isPagingData = () => {
    if (data && data.count > 10) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Table
        className="attachedTests"
        dataSource={data?.shablons}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record.shablon_id}
        onRow={(record) => ({
          onClick: () => {
            onRowClick(record)
          },
        })}
      >
        <Column align="left" title="Test nomi" dataIndex={'shablon_name'} />
        <Column align="center" title="Tur" dataIndex={'type'} />
        <Column
          align="center"
          title="Holat"
          render={(record: AttachedTestData) => (
            <span className={`${handleStatus(record)} status`}>
              {record.finish_student_counts}/{record.student_count}
            </span>
          )}
        />
        <Column
          align="left"
          title="Fanlar"
          render={(record: AttachedTestData) =>
            record.subject_name.map((subject, index) => (
              <p key={index}>{subject}&nbsp;</p>
            ))
          }
        />
        <Column
          align="center"
          title="Boshlanish vaqti"
          render={(record: AttachedTestData) => (
            <div className="test_time">
              <div>
                <p>{dayjs.unix(record.start_at).format('DD.MM.YYYY | hh-mm')}</p>
                <p>{dayjs.unix(record.finish_at).format('DD.MM.YYYY | hh-mm')}</p>
              </div>
              <span>{record.time_limit / 60} daq</span>
            </div>
          )}
        />
        <Column align="right" title="Savollar soni" dataIndex={'question_count'} />
        <Column
          align="center"
          className="more"
          render={(record: AttachedTestData) => (
            <Popover
              overlayClassName="more_popover"
              placement="left"
              trigger="hover click"
              mouseEnterDelay={2}
              mouseLeaveDelay={2}
              content={() => (
                <div className="more_popover_actions">
                  <Popconfirm
                    title="Testni o'chirmoqchimisiz?"
                    onConfirm={(e) => deleteTest(record, e)}
                    okText="Ha"
                    cancelText="Yo'q"
                    placement="left"
                    trigger="hover click"
                    mouseEnterDelay={2}
                    mouseLeaveDelay={2}
                  >
                    <p onClick={(e) => e.stopPropagation()}>O'chirish</p>
                  </Popconfirm>
                </div>
              )}
            >
              {record.status === 'passive' && (
                <div className="more_icon" onClick={(e) => e.stopPropagation()}>
                  <MoreIcon />
                </div>
              )}
            </Popover>
          )}
        />
      </Table>
      {isPagingData() && (
        <div className="pagination">
          <div className="pageSize">
            <p>Har bir sahifadagi qatorlar soni :</p>
            <Select value={pageSize} onSelect={(pageSize) => setPageSize(pageSize)}>
              <Select.Option value="10">10</Select.Option>
              <Select.Option value="20">20</Select.Option>
              <Select.Option value="50">50</Select.Option>
            </Select>
          </div>
          <Pagination
            total={Number(data?.count) || 10}
            pageSize={pageSize}
            onChange={(page) => setPage(page)}
            current={data?.current_page}
            showSizeChanger={false}
          />
        </div>
      )}
    </>
  )
}

export default TestsOfGroup
