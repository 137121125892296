import React from 'react'

const MoreIcon = () => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5 25.3814C32.8807 25.3814 34 24.2621 34 22.8814C34 21.5007 32.8807 20.3814 31.5 20.3814C30.1193 20.3814 29 21.5007 29 22.8814C29 24.2621 30.1193 25.3814 31.5 25.3814Z"
        fill="#123597"
      />
      <path
        d="M31.5 33.3814C32.8807 33.3814 34 32.2621 34 30.8814C34 29.5007 32.8807 28.3814 31.5 28.3814C30.1193 28.3814 29 29.5007 29 30.8814C29 32.2621 30.1193 33.3814 31.5 33.3814Z"
        fill="#123597"
      />
      <path
        d="M31.5 41.3814C32.8807 41.3814 34 40.2621 34 38.8814C34 37.5007 32.8807 36.3814 31.5 36.3814C30.1193 36.3814 29 37.5007 29 38.8814C29 40.2621 30.1193 41.3814 31.5 41.3814Z"
        fill="#123597"
      />
    </svg>
  )
}

export default MoreIcon
