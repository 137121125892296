import React from 'react'

const Download = () => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.71094 2V8H10.2109L7.71094 10.67L5.21094 8H6.71094V2H8.71094ZM10 0.5H5.5V6.74348H1.83568L7.71094 12.5L13.7402 6.74348H10.0456L10 0.5ZM14.7109 14.5H0.710938V16.5H14.7109V14.5Z"
        fill="#0C4292"
      />
    </svg>
  )
}

export default Download
